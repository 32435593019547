import {WindowParam} from "../../../../library/context/WindowContext";
import * as React from "react";
import {FC, useRef} from "react";
import {SizeAttr} from "../../../../library/basic/size";
import {BoxAttr, ColumnAttr, RowAttr} from "../../../../library/basic/compose";
import {ColorAttr} from "../../../../library/basic/color";
import {TcCvTemplatesData} from "../../data/TcCvTemplates";
import {popUpBaseUnit, popUpHeightNum, popUpWidthNum} from "../../display/layout";
import {ImageUnit} from "../../../../library/atomic/image/ImageUnit";
import mixPopupCloseIcon from "../../../../assets/user/popup/mix/mix_pop_up_close_icon.svg";
import {RowUnit} from "../../../../library/atomic/unit/RowUnit";
import {ColumnUnit} from "../../../../library/atomic/unit/ColumnUnit";
import {tosLink} from "../../../../library/util/tosUtil";
import {SpanText} from "../../../../library/atomic/text/SpanText";
import {FontAttr} from "../../../../library/basic/font";
import {GenerationPopupInitProps} from "../../TcCvMainPage";
import {CardScrollingPanel} from "./CardSelectPopup";
import {queryCvMixVideosByTemplateByPage, TcCvMixVideosData} from "../../data/TcCvMixVideos";
import downloadIcon from "../../../../assets/user/popup/mix/detail_download_icon.svg";
import templateIcon from "../../../../assets/user/popup/mix/detail_template_icon.svg";
import {PopupButton} from "./PopupButton";

export function getPopupDescText(creator_name: string, rawTags: string): string {
    let res = "@" + creator_name

    let tags: string[] = []
    try {
        tags = JSON.parse(rawTags) as string[]
    } catch (e) {
    }

    for (let i = 0; i < tags.length && i < 2; i++) {
        res += " #" + tags[i]
    }
    return res
}

interface TemplatePopupProps {
    wp: WindowParam
    template: TcCvTemplatesData
    closeClick: () => void
    showMixVideoDetailCard: (d: TcCvMixVideosData) => void
    showGenerationPopup: (d: GenerationPopupInitProps) => void
}

export const TemplatePopup: FC<TemplatePopupProps> = ({
                                                          wp,
                                                          template,
                                                          closeClick,
                                                          showMixVideoDetailCard,
                                                          showGenerationPopup
                                                      }: TemplatePopupProps) => {

    let bUnit = popUpBaseUnit(wp)
    let pw = popUpWidthNum(wp)
    let ph = popUpHeightNum(wp)

    let leftPw = 590 * bUnit
    let rightPw = pw - leftPw
    let rightInnerPw = rightPw - 32
    let cardWidth = (rightInnerPw - 24) / 4

    let buttonHeight = 56 * bUnit
    let buttonWidth = (rightInnerPw - 8) / 2
    let rightTopHeight = 104 * bUnit
    let cardScrollingPanelHeight = ph - rightTopHeight - buttonHeight - 32

    let queryCvMixVideosDataQueryApi: (req: {
        page_num: number,
        page_size: number
    }, callback: (success: boolean, resp: any) => void) => void = (req, callback) => {
        queryCvMixVideosByTemplateByPage({
            template_id: template.id,
            page_num: req.page_num,
            page_size: req.page_size
        }, callback)
    }

    const templateVideoRef = useRef<HTMLVideoElement>(null);

    return (
        <RowUnit
            stopClickPropagation={true}
            colorAttr={new ColorAttr(wp, "", "#101010")}
            customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                "borderRadius": "20px",
                "border": "2px solid #3A3A3A",
                "position": "relative",
                "overflow": "hidden"
            })}
            sizeAttr={new SizeAttr(wp, pw + "px", ph + "px")}>

            <ImageUnit
                onClick={() => {
                    closeClick()
                }}
                customStyleAttr={{
                    "position": "absolute",
                    "top": (19 * bUnit) + "px",
                    "right": (19 * bUnit) + "px"
                }}
                sizeAttr={new SizeAttr(wp, (34 * bUnit) + "px", (34 * bUnit) + "px")}
                url={mixPopupCloseIcon}/>

            <ColumnUnit
                customStyleAttr={{
                    "background": "#000000"
                }}
                sizeAttr={new SizeAttr(wp, leftPw + "px", ph + "px")}>
                <video
                    ref={templateVideoRef}
                    onContextMenu={(e) => {
                        e.preventDefault()
                    }}
                    controlsList={"nodownload"}
                    autoPlay={true}
                    controls={true}
                    style={Object.assign(BoxAttr.getChildrenPositionObj(), {
                        "maxWidth": (leftPw * 0.8) + "px",
                        "maxHeight": ph + "px"
                    })}
                    src={tosLink(template.org_video)}/>
            </ColumnUnit>

            <ColumnUnit sizeAttr={new SizeAttr(wp, rightPw + "px", ph + "px")}>
                <ColumnUnit
                    customStyleAttr={ColumnAttr.getChildrenPositionObj()}
                    sizeAttr={new SizeAttr(wp, rightInnerPw + "px", ph + "px")}>

                    <ColumnUnit sizeAttr={new SizeAttr(wp, rightInnerPw + "px", rightTopHeight + "px")}>
                        <RowUnit
                            customStyleAttr={{
                                "marginTop": "16px",
                                "marginBottom": (3 * bUnit) + "px"
                            }}>
                            <SpanText
                                customStyleAttr={RowAttr.getChildrenPositionObj()}
                                colorAttr={new ColorAttr(wp, "#ffffff")}
                                fontAttr={new FontAttr(wp, (20.24 * bUnit) + "px", "600")}>
                                {template.name}
                            </SpanText>
                        </RowUnit>
                        <SpanText
                            customStyleAttr={{
                                "justifyContent": "",
                                "marginBottom": (6 * bUnit) + "px"
                            }}
                            colorAttr={new ColorAttr(wp, "#B5B8C5")}
                            fontAttr={new FontAttr(wp, (16.56 * bUnit) + "px", "500")}>
                            {getPopupDescText(template.user.nickname, template.tags)}
                        </SpanText>
                        <SpanText
                            colorAttr={new ColorAttr(wp, "#A1A2A8")}
                            fontAttr={new FontAttr(wp, (18 * bUnit) + "px", "800")}>
                            {"Generations"}
                        </SpanText>
                    </ColumnUnit>

                    <CardScrollingPanel
                        key={"TemplatePopup-CardScrollingPanel-" + template.id}
                        labIndex={"mixVideo"}
                        width={rightInnerPw + "px"}
                        height={cardScrollingPanelHeight + "px"}
                        cardWidth={cardWidth + "px"}
                        cardGap={"8px"}
                        lineCount={4}
                        showDetailCard={(d) => {
                            if (templateVideoRef.current) {
                                templateVideoRef.current.pause();
                            }
                            showMixVideoDetailCard(d)
                        }}
                        dataQueryApi={queryCvMixVideosDataQueryApi}
                    />

                    <RowUnit
                        sizeAttr={new SizeAttr(wp, rightInnerPw + "px", buttonHeight + "px")}
                        customStyleAttr={{
                            "marginTop": "16px"
                        }}
                    >
                        <PopupButton
                            wp={wp} width={buttonWidth + "px"} height={buttonHeight + "px"}
                            fontSize={(20 * bUnit) + "px"}
                            text={"Download Video"}
                            strokeColor={"#646464"}
                            fillColor={"#222222"}
                            icon={() => {
                                return <ImageUnit
                                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                        "marginLeft": (8 * bUnit) + "px"
                                    })}
                                    sizeAttr={new SizeAttr(wp, (30 * bUnit) + "px", (30 * bUnit) + "px")}
                                    url={downloadIcon}/>
                            }}
                            click={() => {
                                window.open(tosLink(template.display_video), "_blank")
                            }}
                            customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                "marginRight": "auto"
                            })}
                        />

                        <PopupButton
                            wp={wp} width={buttonWidth + "px"} height={buttonHeight + "px"}
                            fontSize={(20 * bUnit) + "px"}
                            text={"Use Template"}
                            strokeColor={"linear-gradient(to right, #4DD1C180 0%, #4F78C880 26%, #8F51B880 51%, #BE578180 76%, #BF735380 100%)"}
                            fillColor={"linear-gradient(to right, #172422 0%, #171B23 26%, #1D1721 51%, #22181C 76%, #221A17 100%)"}
                            icon={() => {
                                return <ImageUnit
                                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                        "marginLeft": (8 * bUnit) + "px"
                                    })}
                                    sizeAttr={new SizeAttr(wp, (24 * bUnit) + "px", (24 * bUnit) + "px")}
                                    url={templateIcon}/>
                            }}
                            click={() => {
                                closeClick()
                                showGenerationPopup({
                                    character: undefined,
                                    template: template
                                })
                            }}
                            customStyleAttr={RowAttr.getChildrenPositionObj()}
                        />
                    </RowUnit>
                </ColumnUnit>
            </ColumnUnit>

        </RowUnit>
    )
}