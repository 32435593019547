import {Component} from "react";
import {WindowParamContext} from "../../library/context/WindowContext";
import {SizeAttr} from "../../library/basic/size";
import {ColumnUnit} from "../../library/atomic/unit/ColumnUnit";
import {getDisplayMode} from "./display/layout";
import {FontAttr} from "../../library/basic/font";
import {ColorAttr} from "../../library/basic/color";
import {LabPanel} from "./panel/LabPanel";
import "../../assets/fonts/gina/stylesheet.css";
import {TcCvTemplateCategoryList, TcCvTemplatesData} from "./data/TcCvTemplates";
import {TcCvCharacterCategoryList, TcCvCharactersData} from "./data/TcCvCharacters";
import {
    TcCharacterDetailPanelPadAndPhoneBox,
    TcTemplateDetailPanelPadAndPhoneBox
} from "./component/DetailPanelPadAndPhone";
import {TcCvPromptTopNavPc, TcCvPromptTopNavPhone} from "./component/prompt/TcCvPromptTopNav";
import {PasteOkPanelMobile, PasteOkPanelPad, PasteOkPanelPc} from "./component/prompt/PasteOkPanel";

export type LabIndex = "character" | "template" | "mixVideo"

type TcCvPromptPageState = {
    labIndex: LabIndex,
    showCopyPanel: boolean,
    detailCharacter: TcCvCharactersData | undefined,
    detailTemplate: TcCvTemplatesData | undefined
}

export class TcCvPromptPage extends Component {
    static contextType = WindowParamContext;

    state: TcCvPromptPageState = {
        labIndex: window.location.href.indexOf("character") >= 0 ? "character" : "template",
        showCopyPanel: false,
        detailCharacter: undefined,
        detailTemplate: undefined
    }

    static readonly stateStorageKey = "Aiuni-Ai-TcCvPromptPage-State"

    // 保存组件状态
    saveState(state: TcCvPromptPageState) {
        sessionStorage.setItem(TcCvPromptPage.stateStorageKey, JSON.stringify(state))
    }

    // 更新并保存组件状态
    updateAndSaveState(newData: any, callback?: () => void) {
        this.setState(prevState => (Object.assign(prevState,
            newData)), () => {
            this.saveState(this.state)
            if (callback !== undefined) {
                callback()
            }
        })
    }

    loadState(): TcCvPromptPageState | undefined {
        let storageState = localStorage.getItem(TcCvPromptPage.stateStorageKey)
        let loadedState = undefined
        if (storageState) {
            loadedState = JSON.parse(storageState)
            this.setState(loadedState)
        }
        return loadedState
    }

    componentDidMount() {
        let res = this.loadState()
        if (res != undefined) {
            this.setState(res)
        }
    }

    render() {
        return (
            <WindowParamContext.Consumer>
                {wpt => {
                    let wp = wpt.param;
                    let navHeight = getDisplayMode(wp.currWidth) == "mobile" ? "49px" : "72px"
                    let panelHeight = (wp.currHeight - SizeAttr.getPxNumber(navHeight)) + "px"
                    let panelWidth = SizeAttr.changeVwToPx(wp, "100vw")

                    return (
                        <ColumnUnit
                            fontAttr={new FontAttr(wp, "", "", "\"Gina\"")}
                            colorAttr={new ColorAttr(wp, "#ffffff", "#000000")}
                            sizeAttr={new SizeAttr(wp, "100vw", wp.currHeight + "px")}>

                            {/* 复制面板 */}
                            {
                                this.state.showCopyPanel && getDisplayMode(wp.currWidth) == "pc" &&
                                PasteOkPanelPc(wp, () => {
                                    this.updateAndSaveState({showCopyPanel: false})
                                })
                            }
                            {
                                this.state.showCopyPanel && getDisplayMode(wp.currWidth) == "pad" &&
                                PasteOkPanelPad(wp, () => {
                                    this.updateAndSaveState({showCopyPanel: false})
                                })
                            }
                            {
                                this.state.showCopyPanel && getDisplayMode(wp.currWidth) == "mobile" &&
                                PasteOkPanelMobile(wp, () => {
                                    this.updateAndSaveState({showCopyPanel: false})
                                })
                            }

                            {/* 详情面板 */}
                            {
                                this.state.detailCharacter && getDisplayMode(wp.currWidth) != "pc" &&
                                TcCharacterDetailPanelPadAndPhoneBox(wp, this.state.detailCharacter,
                                    () => {
                                        this.updateAndSaveState({showCopyPanel: true})
                                    },
                                    () => {
                                        this.updateAndSaveState({detailCharacter: undefined})
                                    },
                                )
                            }
                            {
                                this.state.detailTemplate && getDisplayMode(wp.currWidth) != "pc" &&
                                TcTemplateDetailPanelPadAndPhoneBox(wp, this.state.detailTemplate,
                                    () => {
                                        this.updateAndSaveState({showCopyPanel: true})
                                    },
                                    () => {
                                        this.updateAndSaveState({detailTemplate: undefined})
                                    },
                                )
                            }

                            {/* 主导航 */}
                            {
                                getDisplayMode(wp.currWidth) != "mobile" &&
                                TcCvPromptTopNavPc(wp, navHeight, this.state.labIndex, (labIndex: LabIndex) => {
                                    window.location.href = "/prompt/" + (labIndex == "template" ? "template" : "character")
                                })
                            }
                            {
                                getDisplayMode(wp.currWidth) == "mobile" &&
                                TcCvPromptTopNavPhone(wp, navHeight, this.state.labIndex, (labIndex: LabIndex) => {
                                    window.location.href = "/prompt/" + (labIndex == "template" ? "template" : "character")
                                })
                            }

                            {/* 详情面板 */}
                            {
                                this.state.labIndex == "character" &&
                                <LabPanel
                                    categoryList={["ALL"].concat(TcCvCharacterCategoryList)}
                                    panelName={"character"} width={panelWidth} height={panelHeight}
                                    showCopyPanel={() => {
                                        this.updateAndSaveState({showCopyPanel: true})
                                    }}
                                    updateDetailData={(d) => {
                                        this.updateAndSaveState({detailCharacter: d})
                                    }}
                                />
                            }
                            {
                                this.state.labIndex == "template" &&
                                <LabPanel
                                    categoryList={["ALL"].concat(TcCvTemplateCategoryList)}
                                    panelName={"template"} width={panelWidth} height={panelHeight}
                                    showCopyPanel={() => {
                                        this.updateAndSaveState({showCopyPanel: true})
                                    }}
                                    updateDetailData={(d) => {
                                        this.updateAndSaveState({detailTemplate: d})
                                    }}
                                />
                            }

                        </ColumnUnit>
                    )
                }
                }
            </WindowParamContext.Consumer>
        )
    }
}