import {WindowParam} from "../../../../library/context/WindowContext";
import * as React from "react";
import {FC, useState} from "react";
import {SizeAttr} from "../../../../library/basic/size";
import {BoxAttr, ColumnAttr, RowAttr} from "../../../../library/basic/compose";
import {ColorAttr} from "../../../../library/basic/color";
import {BoxUnit} from "../../../../library/atomic/unit/BoxUnit";
import {mediumRadius, popUpBaseUnit, popUpHeightNum, popUpWidthNum} from "../../display/layout";
import {ImageUnit} from "../../../../library/atomic/image/ImageUnit";
import mixPopupCloseIcon from "../../../../assets/user/popup/mix/mix_pop_up_close_icon.svg";
import {ColumnUnit} from "../../../../library/atomic/unit/ColumnUnit";
import {SpanText} from "../../../../library/atomic/text/SpanText";
import {FontAttr} from "../../../../library/basic/font";
import {RowUnit} from "../../../../library/atomic/unit/RowUnit";
import {PopupButton} from "./PopupButton";
import templateUploadIcon from "../../../../assets/user/popup/generate/template_upload_icon.svg";
import characterUploadIcon from "../../../../assets/user/popup/generate/character_upload_icon.svg";
import templateLeftHint1 from "../../../../assets/user/popup/generate/template_upload_h1.jpg";
import templateLeftHint2 from "../../../../assets/user/popup/generate/template_upload_h2.jpg";
import templateLeftHint3 from "../../../../assets/user/popup/generate/template_upload_h3.jpg";
import characterLeftHint1 from "../../../../assets/user/popup/generate/character_upload_h1.png";
import characterLeftHint2 from "../../../../assets/user/popup/generate/character_upload_h2.png";
import characterLeftHint3 from "../../../../assets/user/popup/generate/character_upload_h3.png";
import uploadCloudIcon from "../../../../assets/user/popup/generate/upload_cloud_icon.svg";
import {v4 as uuidv4} from "uuid";
import {tosLink, UploaderMergeUrl, UploaderTouchUrl, UploaderTransportUrl} from "../../../../library/util/tosUtil";
import uploader from "../uploader/web";
import {addCvCharacters, TcCvCharactersData} from "../../data/TcCvCharacters";
import {queryCvJobsById} from "../../data/TcCvJob";
import {removeFileExtension} from "../../../../library/util/stringUtil";
import {LoadingOutlined} from "@ant-design/icons";
import {addCvTemplates, TcCvTemplatesData} from "../../data/TcCvTemplates";
import {GLBPlayer} from "../GlbPlayer";
import {emptyUserDb} from "../../data/TcCvUser";

type FileUploadStatus = {
    file: File | null,
    progress: number,
    status: string,
    tosKey: string
}


interface UploadGuidePopupProps {
    wp: WindowParam
    closeClick: (triggerUpload: boolean) => void
    uploadClick: (d: any) => void
}

export const UploadCharacterGuidePopup: FC<UploadGuidePopupProps> = ({
                                                                         wp,
                                                                         closeClick,
                                                                         uploadClick
                                                                     }: UploadGuidePopupProps) => {
    let inputUuid = uuidv4()

    const [name, setName] = useState<string>("");
    const [fileUploadStatus, setFileUploadStatus] = useState<FileUploadStatus>({
        file: null,
        progress: 0,
        status: "waiting",
        tosKey: ""
    });
    const [inUpload, setInUpload] = useState<boolean>(false);

    let bUnit = popUpBaseUnit(wp)
    let pw = popUpWidthNum(wp)
    let ph = popUpHeightNum(wp)

    let innerPw = pw - 76 * bUnit
    let uploadOuterBoxHeight = 678 * bUnit
    let leftColWidth = 370 * bUnit
    let leftImgWidth = 120 * bUnit
    let leftImgHeight = 176 * bUnit
    let leftTextWidth = 217 * bUnit

    let rightColWidth = 688 * bUnit
    let uploadInnerBoxHeight = 509 * bUnit

    let nameInputWidth = 383 * bUnit
    let nameInputHeight = 48 * bUnit

    let leftBlock = function (img: string, text: string, marginBottom: string = (46 * bUnit) + "px") {
        return (
            <RowUnit
                customStyleAttr={{"marginBottom": marginBottom}}
                sizeAttr={new SizeAttr(wp, (leftImgWidth + leftTextWidth + 24 * bUnit) + "px", leftImgHeight + "px")}>
                <ImageUnit
                    customStyleAttr={{
                        "borderRadius": (14 * bUnit) + "px",
                        "marginRight": "auto",
                        "overflow": "hidden"
                    }}
                    sizeAttr={new SizeAttr(wp, leftImgWidth + "px", leftImgHeight + "px")}
                    url={img}/>
                <SpanText
                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                        "transform": "translateX(-" + (16 * bUnit) + "px)"
                    })}
                    sizeAttr={new SizeAttr(wp, leftTextWidth + "px")}>
                    {text}
                </SpanText>
            </RowUnit>
        )
    }

    return (
        <ColumnUnit
            stopClickPropagation={true}
            colorAttr={new ColorAttr(wp, "", "#111111")}
            customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                "borderRadius": "20px",
                "border": "2px solid #3A3A3A",
                "position": "relative",
                "overflow": "hidden"
            })}
            sizeAttr={new SizeAttr(wp, pw + "px", ph + "px")}>

            <ImageUnit
                onClick={() => {
                    closeClick(false)
                }}
                customStyleAttr={{
                    "position": "absolute",
                    "top": (19 * bUnit) + "px",
                    "right": (19 * bUnit) + "px"
                }}
                sizeAttr={new SizeAttr(wp, (34 * bUnit) + "px", (34 * bUnit) + "px")}
                url={mixPopupCloseIcon}/>

            <ColumnUnit
                fontAttr={new FontAttr(wp, (24 * bUnit) + "px", "500")}
                colorAttr={new ColorAttr(wp, "#a4a4a4")}
                customStyleAttr={ColumnAttr.getChildrenPositionObj()}
                sizeAttr={new SizeAttr(wp, innerPw + "px")}
            >
                <SpanText
                    customStyleAttr={{
                        "marginTop": (30 * bUnit) + "px",
                        "marginBottom": (22 * bUnit) + "px",
                        "marginRight": "auto"
                    }}
                    colorAttr={new ColorAttr(wp, "#ffffff")}
                    fontAttr={new FontAttr(wp, (28 * bUnit) + "px", "500")}
                    sizeAttr={new SizeAttr(wp, "", "42px")}
                >
                    {"3D Character Input Guide"}
                </SpanText>

                <RowUnit sizeAttr={new SizeAttr(wp, innerPw + "px", uploadOuterBoxHeight + "px")}>
                    <ColumnUnit
                        customStyleAttr={{
                            "borderRadius": mediumRadius,
                            "background": "#000000"
                        }}
                        sizeAttr={new SizeAttr(wp, leftColWidth + "px", uploadOuterBoxHeight + "px")}>
                        <ColumnUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                            {leftBlock(characterLeftHint1, "The character should be in A-pose or T-pose for AI rigging")}
                            {leftBlock(characterLeftHint2, "Upload a 3D model use .glb file with all PBR textures embedded")}
                            {leftBlock(characterLeftHint3, "supports any style or size of characters", "")}
                        </ColumnUnit>
                    </ColumnUnit>

                    <ColumnUnit
                        customStyleAttr={{
                            "marginLeft": "auto"
                        }}
                        sizeAttr={new SizeAttr(wp, rightColWidth + "px", uploadOuterBoxHeight + "px")}>

                        <BoxUnit
                            needHover={true}
                            onClick={() => {
                                if (fileUploadStatus.file == null || fileUploadStatus.status != "uploading") {
                                    let inputElement = document.getElementById(inputUuid)
                                    if (inputElement != null) {
                                        inputElement.click()
                                    }
                                }
                            }}
                            customStyleAttr={{
                                "borderRadius": (8 * bUnit) + "px",
                                "background": "#000000"
                            }}
                            sizeAttr={new SizeAttr(wp, rightColWidth + "px", uploadInnerBoxHeight + "px")}>

                            {
                                fileUploadStatus.file == null &&
                                <ColumnUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                                    <ImageUnit
                                        customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                            "marginBottom": (8 * bUnit) + "px"
                                        })}
                                        sizeAttr={new SizeAttr(wp, (112 * bUnit) + "px", (112 * bUnit) + "px")}
                                        url={characterUploadIcon}/>

                                    <span style={ColumnAttr.getChildrenPositionObj()}>
                                        drag or upload a character
                                    </span>
                                    <span style={ColumnAttr.getChildrenPositionObj()}>
                                        Model Limitations (<strong>200k Faces</strong> and <strong>40M max</strong>)
                                    </span>

                                    <PopupButton
                                        wp={wp} width={(158 * bUnit) + "px"} height={(42 * bUnit) + "px"}
                                        fontSize={(22 * bUnit) + "px"}
                                        text={"Upload"}
                                        strokeColor={"#FAFAFA"}
                                        fillColor={"#111111"}
                                        icon={() => {
                                            return <ImageUnit
                                                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                                    "marginLeft": (8 * bUnit) + "px"
                                                })}
                                                sizeAttr={new SizeAttr(wp, (24 * bUnit) + "px", (18 * bUnit) + "px")}
                                                url={uploadCloudIcon}/>
                                        }}
                                        click={() => {

                                        }}
                                        customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                            "marginTop": (18 * bUnit) + "px",
                                            "marginBottom": (12 * bUnit) + "px"
                                        })}
                                    />

                                    <span style={ColumnAttr.getChildrenPositionObj()}>
                                        use .glb file or generate 3d-model
                                    </span>
                                </ColumnUnit>
                            }

                            {
                                fileUploadStatus.file != null && fileUploadStatus.status == "uploading" &&
                                <ColumnUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                                    <LoadingOutlined style={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                        "marginBottom": (16 * bUnit) + "px"
                                    })} size={48}/>
                                    <span
                                        key={"UploadGuidePopup-Character-InnerProgress-" + fileUploadStatus.progress}
                                        style={ColumnAttr.getChildrenPositionObj()}>
                                        Uploading... {fileUploadStatus.progress + "%"}
                                    </span>
                                </ColumnUnit>
                            }

                            {
                                fileUploadStatus.file != null && fileUploadStatus.status == "success" &&
                                <ColumnUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                                    <GLBPlayer
                                        key={"CharacterUpload" + fileUploadStatus.tosKey}
                                        src={tosLink(fileUploadStatus.tosKey)}
                                        width={(rightColWidth * 0.8) + "px"}
                                        height={(uploadInnerBoxHeight * 0.8) + "px"}
                                        disableOperation={true}
                                    />
                                </ColumnUnit>
                            }

                            {
                                fileUploadStatus.file != null && fileUploadStatus.status == "fail" &&
                                <ColumnUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                                    <span style={ColumnAttr.getChildrenPositionObj()}>
                                        Upload Fail, Try Again
                                    </span>
                                    <PopupButton
                                        wp={wp} width={(158 * bUnit) + "px"} height={(42 * bUnit) + "px"}
                                        fontSize={(22 * bUnit) + "px"}
                                        text={"Upload"}
                                        strokeColor={"#FAFAFA"}
                                        fillColor={"#111111"}
                                        icon={() => {
                                            return <ImageUnit
                                                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                                    "marginLeft": (8 * bUnit) + "px"
                                                })}
                                                sizeAttr={new SizeAttr(wp, (24 * bUnit) + "px", (18 * bUnit) + "px")}
                                                url={uploadCloudIcon}/>
                                        }}
                                        click={() => {

                                        }}
                                        customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                            "marginTop": (18 * bUnit) + "px"
                                        })}
                                    />
                                </ColumnUnit>
                            }
                        </BoxUnit>

                        <BoxUnit
                            customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                                "border": "1px solid #474747",
                                "borderRadius": (nameInputHeight / 2) + "px"
                            })}
                            sizeAttr={new SizeAttr(wp, nameInputWidth + "px", nameInputHeight + "px")}>
                            <input
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                                placeholder={"character name"}
                                style={{
                                    "fontSize": (24 * bUnit) + "px",
                                    "margin": "auto",
                                    "textAlign": "center",
                                    "width": (nameInputWidth * 0.8) + "px",
                                    "fontWeight": "500",
                                    "fontFamily": "\"Gina\"",
                                    "color": "#B2B2B2",
                                    "background": "transparent",
                                    "border": "none"
                                }}/>
                        </BoxUnit>

                        <PopupButton
                            wp={wp} width={(235 * bUnit) + "px"} height={(55 * bUnit) + "px"}
                            fontSize={(29 * bUnit) + "px"}
                            text={"Submit"}
                            strokeColor={"linear-gradient(to right, #4DD1C180 0%, #4F78C880 26%, #8F51B880 51%, #BE578180 76%, #BF735380 100%)"}
                            strokeWidth={"1.32px"}
                            fillColor={"linear-gradient(to right, #172422 0%, #171B23 26%, #1D1721 51%, #22181C 76%, #221A17 100%)"}
                            click={() => {
                                if (fileUploadStatus.status == "success" && fileUploadStatus.tosKey != "" && !inUpload) {
                                    setInUpload(true)
                                    addCvCharacters({
                                        name: name,
                                        rig_model: fileUploadStatus.tosKey,
                                        category: ""
                                    }, (success, resp) => {
                                        if (success && resp) {
                                            queryCvJobsById(resp.data.job_id, false, (success, resp) => {
                                                if (success && resp) {
                                                    let nData: TcCvCharactersData = {
                                                        id: "",
                                                        name: name,
                                                        cover: "",
                                                        org_model: "",
                                                        rig_model: fileUploadStatus.tosKey,
                                                        category: "",
                                                        tags: "",
                                                        job: resp.data,
                                                        user: emptyUserDb(),
                                                        file: undefined,
                                                        fileUuid: undefined
                                                    }
                                                    uploadClick(nData)
                                                } else {
                                                    setInUpload(false)
                                                }
                                            })
                                        }
                                    })
                                }
                            }}
                            customStyleAttr={ColumnAttr.getChildrenPositionObj()}
                        />
                    </ColumnUnit>
                </RowUnit>
            </ColumnUnit>

            {/*不可见的上传控件*/}
            <input
                id={inputUuid}
                accept={".glb"}
                onChange={(event) => {
                    if (event.target.files && event.target.files.length > 0) {
                        const file = event.target.files[0];
                        setFileUploadStatus({file: file, progress: 0, status: "uploading", tosKey: ""})
                        setName(removeFileExtension(file.name))

                        const ctx = {
                            maxConcurrency: 5,
                            totalSize: file.size,
                            chunkSize: 1024 * 1024 * 4,
                            uploadUrl: UploaderTransportUrl,
                            mergeUrl: UploaderMergeUrl,
                            touchUrl: UploaderTouchUrl,
                            testChunks: false,
                            ext: '',
                        }
                        const up = uploader(ctx, file)
                        up.on('progress', e => {
                            console.log('[UPLOAD] progess', e)

                        })
                        up.on('success', e => {
                            console.log('[UPLOAD] success', e)
                            if (e.url) {
                                let tosKey = e.url as string
                                if (tosKey.startsWith("https://aiuni.cdn.avar.cn/")) {
                                    tosKey = tosKey.slice("https://aiuni.cdn.avar.cn/".length)
                                }
                                setFileUploadStatus({file: file, progress: 1, status: "success", tosKey: tosKey})
                            } else {
                                setFileUploadStatus({file: file, progress: 1, status: "fail", tosKey: ""})
                            }
                        })
                        up.on('complete', e => {
                            console.log('[UPLOAD] complete', e)
                        })
                        up.on('fail', e => {
                            console.log('[UPLOAD] fail', e)
                            setFileUploadStatus({file: file, progress: 1, status: "fail", tosKey: ""})
                        })
                        up.run()
                    }
                }}
                type={"file"}
                style={{"width": "0", "height": "0", "display": "none"}}/>
        </ColumnUnit>
    )
}

export const UploadTemplateGuidePopup: FC<UploadGuidePopupProps> = ({
                                                                        wp,
                                                                        closeClick,
                                                                        uploadClick
                                                                    }: UploadGuidePopupProps) => {
    let inputUuid = uuidv4()

    const [name, setName] = useState<string>("");
    const [fileUploadStatus, setFileUploadStatus] = useState<FileUploadStatus>({
        file: null,
        progress: 0,
        status: "waiting",
        tosKey: ""
    });
    const [inUpload, setInUpload] = useState<boolean>(false);

    let bUnit = popUpBaseUnit(wp)
    let pw = popUpWidthNum(wp)
    let ph = popUpHeightNum(wp)

    let innerPw = pw - 76 * bUnit
    let uploadOuterBoxHeight = 678 * bUnit
    let leftColWidth = 370 * bUnit
    let leftImgWidth = 96 * bUnit
    let leftImgHeight = 170 * bUnit
    let leftTextWidth = 217 * bUnit

    let rightColWidth = 688 * bUnit
    let uploadInnerBoxHeight = 509 * bUnit

    let nameInputWidth = 383 * bUnit
    let nameInputHeight = 48 * bUnit

    let leftBlock = function (img: string, text: string, marginBottom: string = (52 * bUnit) + "px") {
        return (
            <RowUnit
                customStyleAttr={{"marginBottom": marginBottom}}
                sizeAttr={new SizeAttr(wp, (leftImgWidth + leftTextWidth + 24 * bUnit) + "px", leftImgHeight + "px")}>
                <ImageUnit
                    customStyleAttr={{
                        "borderRadius": (14 * bUnit) + "px",
                        "marginRight": "auto",
                        "overflow": "hidden"
                    }}
                    sizeAttr={new SizeAttr(wp, leftImgWidth + "px", leftImgHeight + "px")}
                    url={img}/>
                <SpanText
                    customStyleAttr={RowAttr.getChildrenPositionObj()}
                    sizeAttr={new SizeAttr(wp, leftTextWidth + "px")}>
                    {text}
                </SpanText>
            </RowUnit>
        )
    }

    return (
        <ColumnUnit
            stopClickPropagation={true}
            colorAttr={new ColorAttr(wp, "", "#111111")}
            customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                "borderRadius": "20px",
                "border": "2px solid #3A3A3A",
                "position": "relative",
                "overflow": "hidden"
            })}
            sizeAttr={new SizeAttr(wp, pw + "px", ph + "px")}>

            <ImageUnit
                onClick={() => {
                    closeClick(false)
                }}
                customStyleAttr={{
                    "position": "absolute",
                    "top": (19 * bUnit) + "px",
                    "right": (19 * bUnit) + "px"
                }}
                sizeAttr={new SizeAttr(wp, (34 * bUnit) + "px", (34 * bUnit) + "px")}
                url={mixPopupCloseIcon}/>

            <ColumnUnit
                fontAttr={new FontAttr(wp, (24 * bUnit) + "px", "500")}
                colorAttr={new ColorAttr(wp, "#a4a4a4")}
                customStyleAttr={ColumnAttr.getChildrenPositionObj()}
                sizeAttr={new SizeAttr(wp, innerPw + "px")}
            >
                <SpanText
                    customStyleAttr={{
                        "marginTop": (30 * bUnit) + "px",
                        "marginBottom": (22 * bUnit) + "px",
                        "marginRight": "auto"
                    }}
                    colorAttr={new ColorAttr(wp, "#ffffff")}
                    fontAttr={new FontAttr(wp, (28 * bUnit) + "px", "500")}
                    sizeAttr={new SizeAttr(wp, "", "42px")}
                >
                    {"3D Template Input Guide"}
                </SpanText>

                <RowUnit sizeAttr={new SizeAttr(wp, innerPw + "px", uploadOuterBoxHeight + "px")}>
                    <ColumnUnit
                        customStyleAttr={{
                            "borderRadius": mediumRadius,
                            "background": "#111111"
                        }}
                        sizeAttr={new SizeAttr(wp, leftColWidth + "px", uploadOuterBoxHeight + "px")}>
                        <ColumnUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                            {leftBlock(templateLeftHint1, "Upload a video with one character and clear motion")}
                            {leftBlock(templateLeftHint2, "Character is unobstructed with continuous shots")}
                            {leftBlock(templateLeftHint3, "Supports any video ratio and background type", "")}
                        </ColumnUnit>
                    </ColumnUnit>

                    <ColumnUnit
                        customStyleAttr={{
                            "marginLeft": "auto"
                        }}
                        sizeAttr={new SizeAttr(wp, rightColWidth + "px", uploadOuterBoxHeight + "px")}>

                        <BoxUnit
                            needHover={true}
                            onClick={() => {
                                if (fileUploadStatus.file == null || fileUploadStatus.status != "uploading") {
                                    let inputElement = document.getElementById(inputUuid)
                                    if (inputElement != null) {
                                        inputElement.click()
                                    }
                                }
                            }}
                            customStyleAttr={{
                                "borderRadius": (8 * bUnit) + "px",
                                "background": "#000000"
                            }}
                            sizeAttr={new SizeAttr(wp, rightColWidth + "px", uploadInnerBoxHeight + "px")}>

                            {
                                fileUploadStatus.file == null &&
                                <ColumnUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                                    <ImageUnit
                                        customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                            "marginBottom": (8 * bUnit) + "px"
                                        })}
                                        sizeAttr={new SizeAttr(wp, (112 * bUnit) + "px", (112 * bUnit) + "px")}
                                        url={templateUploadIcon}/>

                                    <span style={ColumnAttr.getChildrenPositionObj()}>
                                        drag or upload a video
                                    </span>
                                    <span style={ColumnAttr.getChildrenPositionObj()}>
                                        Video Limitations (<strong>30 Seconds</strong> and <strong>30M max</strong>)
                                    </span>

                                    <PopupButton
                                        wp={wp} width={(158 * bUnit) + "px"} height={(42 * bUnit) + "px"}
                                        fontSize={(22 * bUnit) + "px"}
                                        text={"Upload"}
                                        strokeColor={"#FAFAFA"}
                                        fillColor={"#111111"}
                                        icon={() => {
                                            return <ImageUnit
                                                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                                    "marginLeft": (8 * bUnit) + "px"
                                                })}
                                                sizeAttr={new SizeAttr(wp, (24 * bUnit) + "px", (18 * bUnit) + "px")}
                                                url={uploadCloudIcon}/>
                                        }}
                                        click={() => {

                                        }}
                                        customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                            "marginTop": (18 * bUnit) + "px"
                                        })}
                                    />
                                </ColumnUnit>
                            }

                            {
                                fileUploadStatus.file != null && fileUploadStatus.status == "uploading" &&
                                <ColumnUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                                    <LoadingOutlined style={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                        "marginBottom": (16 * bUnit) + "px"
                                    })} size={48}/>
                                    <span
                                        key={"UploadGuidePopup-Template-InnerProgress-" + fileUploadStatus.progress}
                                        style={ColumnAttr.getChildrenPositionObj()}>
                                        Uploading... {fileUploadStatus.progress + "%"}
                                    </span>
                                </ColumnUnit>
                            }

                            {
                                fileUploadStatus.file != null && fileUploadStatus.status == "success" &&
                                <ColumnUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                                    <video
                                        onContextMenu={(e) => {
                                            e.preventDefault()
                                        }}
                                        controlsList={"nodownload"}
                                        autoPlay={true}
                                        controls={true}
                                        style={Object.assign(BoxAttr.getChildrenPositionObj(), {
                                            "maxWidth": (rightColWidth * 0.8) + "px",
                                            "maxHeight": (uploadInnerBoxHeight * 0.8) + "px"
                                        })}
                                        src={tosLink(fileUploadStatus.tosKey)}/>
                                </ColumnUnit>
                            }

                            {
                                fileUploadStatus.file != null && fileUploadStatus.status == "fail" &&
                                <ColumnUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                                    <span style={ColumnAttr.getChildrenPositionObj()}>
                                        Upload Fail, Try Again
                                    </span>
                                    <PopupButton
                                        wp={wp} width={(158 * bUnit) + "px"} height={(42 * bUnit) + "px"}
                                        fontSize={(22 * bUnit) + "px"}
                                        text={"Upload"}
                                        strokeColor={"#FAFAFA"}
                                        fillColor={"#111111"}
                                        icon={() => {
                                            return <ImageUnit
                                                customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                                    "marginLeft": (8 * bUnit) + "px"
                                                })}
                                                sizeAttr={new SizeAttr(wp, (24 * bUnit) + "px", (18 * bUnit) + "px")}
                                                url={uploadCloudIcon}/>
                                        }}
                                        click={() => {

                                        }}
                                        customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                            "marginTop": (18 * bUnit) + "px"
                                        })}
                                    />
                                </ColumnUnit>
                            }
                        </BoxUnit>

                        <BoxUnit
                            customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                                "border": "1px solid #474747",
                                "borderRadius": (nameInputHeight / 2) + "px"
                            })}
                            sizeAttr={new SizeAttr(wp, nameInputWidth + "px", nameInputHeight + "px")}>
                            <input
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                                placeholder={"video name"}
                                style={{
                                    "fontSize": (24 * bUnit) + "px",
                                    "margin": "auto",
                                    "textAlign": "center",
                                    "width": (nameInputWidth * 0.8) + "px",
                                    "fontWeight": "500",
                                    "fontFamily": "\"Gina\"",
                                    "color": "#B2B2B2",
                                    "background": "transparent",
                                    "border": "none"
                                }}/>
                        </BoxUnit>

                        <PopupButton
                            wp={wp} width={(235 * bUnit) + "px"} height={(55 * bUnit) + "px"}
                            fontSize={(29 * bUnit) + "px"}
                            text={"Submit"}
                            strokeColor={"linear-gradient(to right, #4DD1C180 0%, #4F78C880 26%, #8F51B880 51%, #BE578180 76%, #BF735380 100%)"}
                            strokeWidth={"1.32px"}
                            fillColor={"linear-gradient(to right, #172422 0%, #171B23 26%, #1D1721 51%, #22181C 76%, #221A17 100%)"}
                            click={() => {
                                if (fileUploadStatus.status == "success" && fileUploadStatus.tosKey != "" && !inUpload) {
                                    setInUpload(true)
                                    addCvTemplates({
                                        name: name,
                                        org_video: fileUploadStatus.tosKey,
                                        category: "",
                                        tags: ""
                                    }, (success, resp) => {
                                        if (success && resp) {
                                            queryCvJobsById(resp.data.job_id, false, (success, resp) => {
                                                if (success && resp) {
                                                    let nData: TcCvTemplatesData = {
                                                        id: "",
                                                        name: name,
                                                        cover: "",
                                                        display_video: "",
                                                        inpaint_video: "",
                                                        org_video: fileUploadStatus.tosKey,
                                                        category: "",
                                                        bvh: "",
                                                        tags: "",
                                                        job: resp.data,
                                                        user: emptyUserDb(),
                                                        file: undefined,
                                                        fileUuid: undefined
                                                    }
                                                    uploadClick(nData)
                                                } else {
                                                    setInUpload(false)
                                                }
                                            })
                                        }
                                    })
                                }
                            }}
                            customStyleAttr={ColumnAttr.getChildrenPositionObj()}
                        />
                    </ColumnUnit>
                </RowUnit>
            </ColumnUnit>

            {/*不可见的上传控件*/}
            <input
                id={inputUuid}
                accept={".mp4"}
                onChange={(event) => {
                    if (event.target.files && event.target.files.length > 0) {
                        const file = event.target.files[0];
                        setFileUploadStatus({file: file, progress: 0, status: "uploading", tosKey: ""})
                        setName(removeFileExtension(file.name))

                        const ctx = {
                            maxConcurrency: 5,
                            totalSize: file.size,
                            chunkSize: 1024 * 1024 * 4,
                            uploadUrl: UploaderTransportUrl,
                            mergeUrl: UploaderMergeUrl,
                            touchUrl: UploaderTouchUrl,
                            testChunks: false,
                            ext: '',
                        }
                        const up = uploader(ctx, file)
                        up.on('progress', e => {
                            console.log('[UPLOAD] progess', e)

                        })
                        up.on('success', e => {
                            console.log('[UPLOAD] success', e)
                            if (e.url) {
                                let tosKey = e.url as string
                                if (tosKey.startsWith("https://aiuni.cdn.avar.cn/")) {
                                    tosKey = tosKey.slice("https://aiuni.cdn.avar.cn/".length)
                                }
                                console.log("got tos key", tosKey)
                                setFileUploadStatus({file: file, progress: 1, status: "success", tosKey: tosKey})
                            } else {
                                setFileUploadStatus({file: file, progress: 1, status: "fail", tosKey: ""})
                            }
                        })
                        up.on('complete', e => {
                            console.log('[UPLOAD] complete', e)
                        })
                        up.on('fail', e => {
                            console.log('[UPLOAD] fail', e)
                            setFileUploadStatus({file: file, progress: 1, status: "fail", tosKey: ""})
                        })
                        up.run()
                    }
                }}
                type={"file"}
                style={{"width": "0", "height": "0", "display": "none"}}/>
        </ColumnUnit>
    )
}