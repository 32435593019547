import React, {Component} from "react";
import {WindowParam, WindowParamContext} from "../../../library/context/WindowContext";
import {IDBPDatabase} from "idb";
import {deleteData, getData, initDatabase, MyData, updateData} from "../../../library/util/databaseUtil";
import {SizeAttr} from "../../../library/basic/size";
import {RowUnit} from "../../../library/atomic/unit/RowUnit";
import {ColumnUnit} from "../../../library/atomic/unit/ColumnUnit";
import {BoxAttr, ColumnAttr, RowAttr} from "../../../library/basic/compose";
import {BoxUnit} from "../../../library/atomic/unit/BoxUnit";
import {LabCardData, LabPanelProps} from "./LabPanel";
import {SearchBox} from "./SearchBox";
import {queryTcCvCharactersByPage, TcCvCharactersData, TcCvCharactersPaginationResp} from "../data/TcCvCharacters";
import {queryTcCvTemplatesByPage, TcCvTemplatesData, TcCvTemplatesPaginationResp} from "../data/TcCvTemplates";
import "./CategoryButtonPcAndPad.css"
import {mediumPadding, smallPadding} from "../display/layout";
import {LoadingOutlined} from "@ant-design/icons";
import {splitArrayIntoGroups} from "../../../library/util/arrayUtil";
import {TcCharacterCard, TcTemplateCard} from "../component/PagingCard";

export type LabPanelPhoneState = {
    currentCategory: string
    searchKeyword: string
    scrollPosition: number,
    scrollHeight: number,
    isLoading: boolean,
    dataFetched: boolean,
    pageNum: number,
    total: number,
    dataList: LabCardData[]
}

export class LabPanelPhone extends Component<LabPanelProps> {
    static contextType = WindowParamContext;

    labPanelStateDb: IDBPDatabase<MyData> | null = null
    wp: WindowParam = new WindowParam()
    private readonly scrollContainerRef: React.RefObject<HTMLElement>

    constructor(props: any) {
        super(props);
        this.scrollContainerRef = React.createRef<HTMLElement>();
    }

    stateSessionStorageKey(): string {
        return "LabPanelPhone-Session-State" + "-" + this.props.panelName
    }

    stateStorageKey(): string {
        return "LabPanelPhone-State" + "-" + this.props.panelName
    }

    state: LabPanelPhoneState = {
        currentCategory: "ALL",
        searchKeyword: "",
        scrollPosition: 0,
        scrollHeight: 0,
        isLoading: false,
        dataFetched: false,
        pageNum: 0,
        total: 0,
        dataList: []
    }

    // 保存组件状态
    saveState(state: LabPanelPhoneState) {
        if (this.labPanelStateDb != null) {
            // console.log(this.stateStorageKey())
            updateData(this.labPanelStateDb, this.stateStorageKey(), 1,
                {id: 1, value: JSON.stringify(state)}).then(() => {
                // console.log("LabPanelPhoneState save", this.state)
            })
        }
    }

    // 更新并保存组件状态
    updateAndSaveState(newData: any, callback?: () => void) {
        this.setState(prevState => (Object.assign(prevState,
            newData)), () => {
            this.saveState(this.state)
            if (callback !== undefined) {
                callback()
            }
        })
    }

    // 从db中加载组件状态
    loadState(callback: (loadedState: LabPanelPhoneState | undefined) => void) {
        let getDataCall = () => {
            let loadedState = undefined
            // console.log("loadState before getData", this.labPanelStateDb)
            if (this.labPanelStateDb != null) {
                getData(this.labPanelStateDb, this.stateStorageKey(), 1).then((storageStateData) => {
                    // console.log("loadState after getData", storageStateData)
                    if (storageStateData != undefined) {
                        try {
                            loadedState = JSON.parse(storageStateData.value)
                            // console.log(loadedState)
                            callback(loadedState)
                        } catch (e) {
                            callback(undefined)
                        }
                    } else {
                        callback(undefined)
                    }
                }).catch(error => {
                    callback(undefined)
                })
            } else {
                callback(undefined)
            }
        }

        if (sessionStorage.getItem(this.stateSessionStorageKey()) == null) {
            sessionStorage.setItem(this.stateSessionStorageKey(), "init")
            // 从 indexDB 中清理状态数据
            if (this.labPanelStateDb != null) {
                let labPanelStateDb = this.labPanelStateDb
                getData(labPanelStateDb, this.stateStorageKey(), 1).then((storageStateData) => {
                    // console.log("loadState after getData", storageStateData)
                    if (storageStateData != undefined) {
                        deleteData(labPanelStateDb, this.stateStorageKey(), 1).then(() => {
                            getDataCall()
                        })
                    } else {
                        getDataCall()
                    }
                }).catch(error => {
                    getDataCall()
                })
            } else {
                getDataCall()
            }
        } else {
            getDataCall()
        }
    }

    componentDidMount() {
        // 初始化db，并加载状态
        initDatabase("LabPanelPhone-" + this.props.panelName, this.stateStorageKey()).then((db) => {
            this.labPanelStateDb = db
            let loadedStateCallback: (loadedState: LabPanelPhoneState | undefined) => void = (loadedState) => {
                if (loadedState != undefined) {
                    this.setState(loadedState)
                    if (!loadedState.dataFetched) {
                        this.queryNextPage()
                    }
                    if (this.scrollContainerRef.current != null) {
                        this.scrollContainerRef.current.scrollTop = loadedState.scrollPosition;
                    }
                } else {
                    if (!this.state.dataFetched) {
                        this.queryNextPage()
                    }
                }
            }
            this.loadState(loadedStateCallback)
        })
    }

    triggerSearch() {
        this.setState(prevState => (Object.assign(prevState,
            {
                scrollPosition: 0,
                scrollHeight: 0,
                isLoading: false,
                dataFetched: false,
                pageNum: 0,
                total: 0,
                dataList: []
            })), () => {
            if (this.scrollContainerRef.current != null) {
                this.scrollContainerRef.current.scrollTop = this.state.scrollPosition;
            }
            this.queryNextPage()
        })
    }

    queryNextPage() {
        if (this.state.isLoading) return
        if (this.state.dataFetched && this.state.dataList.length >= this.state.total) {
            return;
        }

        let that = this
        let respCallback = function (success: boolean, resp: TcCvCharactersPaginationResp | TcCvTemplatesPaginationResp | undefined) {
            if (success && resp != undefined) {
                that.updateAndSaveState({
                    "scrollPosition": that.state.pageNum + 1 == 1 ? 0 : that.state.scrollPosition,
                    "dataFetched": true,
                    "isLoading": false,
                    "pageNum": that.state.pageNum + 1,
                    "total": resp.data.total,
                    "dataList": that.state.pageNum + 1 == 1 ? resp.data.list : that.state.dataList.concat(resp.data.list),
                }, () => {
                    if (that.scrollContainerRef.current != null) {
                        that.scrollContainerRef.current.scrollTop = that.state.scrollPosition;
                    }
                })
            } else {
                that.updateAndSaveState({
                    "scrollPosition": that.state.pageNum + 1 == 1 ? 0 : that.state.scrollPosition,
                    "dataFetched": true,
                    "isLoading": false,
                    "pageNum": 0,
                    "total": 0,
                    "dataList": [],
                }, () => {
                    if (that.scrollContainerRef.current != null) {
                        that.scrollContainerRef.current.scrollTop = that.state.scrollPosition;
                    }
                })
            }
        }

        this.setState(prevState => (Object.assign(prevState,
            {
                "isLoading": true
            }) as LabPanelPhoneState), () => {
            if (this.props.panelName == "character") {
                // 发起调用
                queryTcCvCharactersByPage({
                    category: this.state.currentCategory,
                    keyword: this.state.searchKeyword,
                    page_num: this.state.pageNum + 1,
                    page_size: 10
                }, (success, resp) => {
                    respCallback(success, resp)
                })
            } else if (this.props.panelName == "template") {
                // 发起调用
                queryTcCvTemplatesByPage({
                    category: this.state.currentCategory,
                    keyword: this.state.searchKeyword,
                    page_num: this.state.pageNum + 1,
                    page_size: 10
                }, (success, resp) => {
                    respCallback(success, resp)
                })
            }
        })
    }

    render() {
        return (
            <WindowParamContext.Consumer>
                {wpt => {
                    let wp = wpt.param;
                    let {panelName, width, height, categoryList} = this.props

                    let widthNum = SizeAttr.getPxNumber(width)
                    let heightNum = SizeAttr.getPxNumber(height)

                    // 搜索布局
                    let searchBoxWidthNum = widthNum - 36
                    let searchBoxHeightNum = 32
                    let searchBoxMarginTop = 12

                    // 顶部标签布局
                    let categoryRowWidthNum = searchBoxWidthNum
                    let categoryRowHeightNum = 39

                    // 滚动面板高度
                    let cardScrollingPanelHeightNum = heightNum - searchBoxMarginTop - searchBoxHeightNum
                        - categoryRowHeightNum
                    let cardGap = 12
                    let cardRowWidthNum = widthNum - 36
                    let cardWidthNum = (cardRowWidthNum - cardGap) / 2

                    return (
                        <ColumnUnit sizeAttr={new SizeAttr(wp, width, height)}>
                            <RowUnit customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                "marginTop": searchBoxMarginTop + "px"
                            })}>
                                {SearchBox(wp, searchBoxWidthNum + "px", this.state.searchKeyword,
                                    (v) => {
                                        this.updateAndSaveState({searchKeyword: v})
                                    },
                                    () => {
                                        this.triggerSearch()
                                    }
                                )}
                            </RowUnit>

                            <BoxUnit
                                customStyleAttr={{
                                    "boxShadow": "0 2px 4px 0 rgba(255, 255, 255, 0.25)",
                                }}
                                sizeAttr={new SizeAttr(wp, width, categoryRowHeightNum + "px")}>
                                <RowUnit
                                    classname={"lab-panel-container"}
                                    customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                                        "overflowX": "scroll",
                                        // "position": "relative"
                                    })}
                                    sizeAttr={new SizeAttr(wp, categoryRowWidthNum + "px", categoryRowHeightNum + "px")}
                                >
                                    {
                                        this.props.categoryList.map((c, idx) => {
                                            return (
                                                <div
                                                    onClick={() => {
                                                        this.updateAndSaveState({currentCategory: c}, () => {
                                                            this.triggerSearch()
                                                        })
                                                    }}
                                                    className={`category-button-phone ${c == this.state.currentCategory ? 'clicked' : ''}`}
                                                    style={{
                                                        "marginLeft": idx == 0 ? mediumPadding : smallPadding,
                                                        "marginRight": idx == this.props.categoryList.length - 1 ? mediumPadding : smallPadding,
                                                        "display": "flex",
                                                        "height": categoryRowHeightNum + "px",
                                                        "fontSize": "18px",
                                                        "fontWeight": "600",
                                                        "position": "relative"
                                                    }}>
                                                    <span style={{"margin": "auto"}}>
                                                        {c == "Movie & TV" ? "Movie" : c}
                                                    </span>
                                                    <div
                                                        className={`category-button-phone-indicator ${c == this.state.currentCategory ? 'clicked' : ''}`}
                                                        style={{
                                                            "position": "absolute",
                                                            "width": "32px",
                                                            "height": "4px",
                                                            "bottom": "0",
                                                            "left": "50%", /* 将子元素的左边缘移动到父容器的中心 */
                                                            "transform": "translateX(-50%)", /* 将子元素向左移动自身宽度的一半，实现居中 */
                                                            "transition": "background 0.5s"
                                                        }}
                                                    >
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </RowUnit>
                            </BoxUnit>

                            <ColumnUnit
                                onScroll={(e) => {
                                    let target = e.target as HTMLDivElement
                                    if (target) {
                                        let activeScrollTop = target.scrollTop
                                        this.setState(prevState => (Object.assign(prevState,
                                            {
                                                "scrollPosition": activeScrollTop,
                                                "scrollHeight": target.scrollHeight
                                            })), () => {
                                            if (target.scrollTop != 0) {
                                                this.saveState(this.state)
                                            }
                                        })
                                        if (activeScrollTop == 0) {
                                            this.updateAndSaveState({
                                                "dataFetched": false,
                                                "pageNum": 0,
                                                "pageSize": 10,
                                                "total": 0
                                            }, () => {
                                                this.queryNextPage()
                                            })
                                        } else if (activeScrollTop >= target.scrollHeight - wp.currHeight) {
                                            this.queryNextPage()
                                        }
                                    }
                                }}
                                unitRef={this.scrollContainerRef}
                                customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {"overflowY": "auto"})}
                                sizeAttr={new SizeAttr(wp, width, cardScrollingPanelHeightNum + "px")}>

                                <ColumnUnit>
                                    {
                                        this.state.dataList.length >= 0 &&
                                        splitArrayIntoGroups(this.state.dataList, 2).map((rowDataList, idx) => {
                                            return (
                                                <RowUnit
                                                    customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                                        "marginTop": smallPadding,
                                                        "marginBottom": smallPadding
                                                    })}
                                                    sizeAttr={new SizeAttr(wp, cardRowWidthNum + "px")}
                                                >
                                                    {
                                                        rowDataList.map((rd, rIdx) => {
                                                            if (!rd) {
                                                                return <div></div>
                                                            }

                                                            if (this.props.panelName == "character") {
                                                                return <TcCharacterCard
                                                                    wp={wp}
                                                                    width={cardWidthNum + "px"}
                                                                    fontSize={"18px"}
                                                                    marginRight={rIdx == 1 ? "" : cardGap + "px"}
                                                                    tcCvCharactersData={rd as TcCvCharactersData}
                                                                    selected={false}
                                                                    onClick={(d) => {
                                                                        this.props.updateDetailData(d)
                                                                    }}
                                                                    mouseEnterCallback={(d) => {
                                                                    }}
                                                                />
                                                            } else if (this.props.panelName == "template") {
                                                                return <TcTemplateCard
                                                                    wp={wp}
                                                                    width={cardWidthNum + "px"}
                                                                    fontSize={"18px"}
                                                                    marginRight={rIdx == 1 ? "" : cardGap + "px"}
                                                                    tcCvTemplatesData={rd as TcCvTemplatesData}
                                                                    selected={false}
                                                                    onClick={(d) => {
                                                                        this.props.updateDetailData(d)
                                                                    }}
                                                                    mouseEnterCallback={(d) => {
                                                                        this.updateAndSaveState({selectedId: d.id})
                                                                    }}
                                                                />
                                                            } else {
                                                                return <div></div>
                                                            }
                                                        })
                                                    }
                                                </RowUnit>
                                            )
                                        })
                                    }

                                    <RowUnit sizeAttr={new SizeAttr(wp, width, "64px")}>
                                        {
                                            this.state.isLoading && this.state.pageNum > 0 &&
                                            <LoadingOutlined
                                                size={48}
                                                style={BoxAttr.getChildrenPositionObj()}/>
                                        }
                                    </RowUnit>
                                </ColumnUnit>
                            </ColumnUnit>

                        </ColumnUnit>
                    )
                }
                }
            </WindowParamContext.Consumer>
        )
    }
}