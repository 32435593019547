import {WindowParam, WindowParamContext} from "../../../../library/context/WindowContext";
import * as React from "react";
import {Component, FC, useState} from "react";
import {SizeAttr} from "../../../../library/basic/size";
import {BoxAttr, ColumnAttr, RowAttr} from "../../../../library/basic/compose";
import {ColorAttr} from "../../../../library/basic/color";
import {BoxUnit} from "../../../../library/atomic/unit/BoxUnit";
import {mediumRadius, popUpBaseUnit, popUpHeightNum, popUpWidthNum, smallPadding} from "../../display/layout";
import {ImageUnit} from "../../../../library/atomic/image/ImageUnit";
import generationCloseIcon from "../../../../assets/prompt/copy/copy_panel_close_icon.svg";
import {ColumnUnit} from "../../../../library/atomic/unit/ColumnUnit";
import {RowUnit} from "../../../../library/atomic/unit/RowUnit";
import {LabIndex} from "../../TcCvPromptPage";
import {splitArrayIntoGroups} from "../../../../library/util/arrayUtil";
import {TcCharacterCard, TcMixVideoCard, TcTemplateCard} from "../PagingCard";
import {LoadingOutlined} from "@ant-design/icons";
import "./CardSelectPopup.css";
import "../../panel/LabPanel.css";
import {
    TcCvCharacterCategoryList,
    getInitCharacterDataFromFile,
    queryTcCvCharactersByPage,
    queryUserCvCharactersByPage
} from "../../data/TcCvCharacters";
import {
    getInitTemplateDataFromFile,
    queryTcCvTemplatesByPage,
    queryUserCvTemplatesByPage, TcCvTemplateCategoryList
} from "../../data/TcCvTemplates";
import {CategoryButtonPcAndPad} from "../../panel/CategoryButtonPcAndPad";
import {SearchBox} from "../../panel/SearchBox";
import {PopupButton} from "./PopupButton";
import {tosLink} from "../../../../library/util/tosUtil";
import {GLBPlayer} from "../GlbPlayer";
import {SpanText} from "../../../../library/atomic/text/SpanText";
import {FontAttr} from "../../../../library/basic/font";
import uploadCloudIcon from "../../../../assets/user/popup/generate/upload_cloud_icon.svg";
import selectPopupCharacterIcon from "../../../../assets/user/popup/generate/select_popup_character_icon.svg";
import selectPopupTemplateIcon from "../../../../assets/user/popup/generate/select_popup_template_icon.svg";

export type SelectSource = "recommend" | "self"

export interface CardSelectPopupProps {
    wp: WindowParam
    labIndex: LabIndex,
    selectSource: SelectSource,
    dataConfirm: (d: any) => void,
    triggerUpload: (labIndex: LabIndex) => void,
    registerDataCallback: (dataCallback: (d: any) => void) => void,
    closeClick: () => void
}

export const CardSelectPopup: FC<CardSelectPopupProps> = ({
                                                              wp,
                                                              labIndex,
                                                              selectSource,
                                                              dataConfirm,
                                                              triggerUpload,
                                                              registerDataCallback,
                                                              closeClick
                                                          }: CardSelectPopupProps) => {
    let bUnit = popUpBaseUnit(wp)
    let pw = popUpWidthNum(wp)
    let ph = popUpHeightNum(wp)

    let innerPw = pw - 54 * bUnit
    let innerPh = ph - 38 * bUnit

    let leftColWidth = 852 * bUnit
    let categoryAvailableWidth = 638 * bUnit
    let searchWidth = 201 * bUnit
    let categoryItemHeight = 35 * bUnit
    let searchHeight = 32 * bUnit

    let rightColWidth = 253 * bUnit
    let rightDetailHeight = 368 * bUnit

    // 左边布局
    let leftFixedTop = 46 * bUnit
    if (selectSource == "self") {
        leftFixedTop = 76 * bUnit
    }
    let leftScrollHeight = innerPh - leftFixedTop

    const [category, setCategory] = useState<string>("ALL");
    const [keyword, setKeyword] = useState<string>("");
    const [activeKeyword, setActiveKeyword] = useState<string>("");
    const [detailData, setDetailData] = useState<any>(undefined);

    // 数据搜索API
    let dataQueryApi: (req: {
        page_num: number,
        page_size: number
    }, callback: (success: boolean, resp: any) => void) => void = (req, callback) => {
    }
    if (selectSource == "recommend") {
        if (labIndex == "character") {
            dataQueryApi = (req, callback) => {
                queryTcCvCharactersByPage({
                    category: category == "ALL" ? "" : category,
                    keyword: activeKeyword,
                    page_num: req.page_num,
                    page_size: req.page_size
                }, callback)
            }
        } else if (labIndex == "template") {
            dataQueryApi = (req, callback) => {
                queryTcCvTemplatesByPage({
                    category: category == "ALL" ? "" : category,
                    keyword: activeKeyword,
                    page_num: req.page_num,
                    page_size: req.page_size
                }, callback)
            }
        }
    } else if (selectSource == "self") {
        if (labIndex == "character") {
            dataQueryApi = (req, callback) => {
                queryUserCvCharactersByPage({page_num: req.page_num, page_size: req.page_size}, callback)
            }
        } else if (labIndex == "template") {
            dataQueryApi = (req, callback) => {
                queryUserCvTemplatesByPage({page_num: req.page_num, page_size: req.page_size}, callback)
            }
        }
    }

    let categories = labIndex == "character" ? ["ALL"].concat(TcCvCharacterCategoryList) : ["ALL"].concat(TcCvTemplateCategoryList)

    return (
        <BoxUnit
            stopClickPropagation={true}
            colorAttr={new ColorAttr(wp, "", "#161616")}
            customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                "borderRadius": "20px",
                "border": "2px solid #3A3A3A",
                "position": "relative",
                "overflow": "hidden"
            })}
            sizeAttr={new SizeAttr(wp, pw + "px", ph + "px")}>

            <ImageUnit
                onClick={() => {
                    closeClick()
                }}
                customStyleAttr={{
                    "position": "absolute",
                    "top": (19 * bUnit) + "px",
                    "right": (19 * bUnit) + "px"
                }}
                sizeAttr={new SizeAttr(wp, (21 * bUnit) + "px", (21 * bUnit) + "px")}
                url={generationCloseIcon}/>

            <RowUnit
                customStyleAttr={BoxAttr.getChildrenPositionObj()}
                sizeAttr={new SizeAttr(wp, innerPw + "px", innerPh + "px")}>

                {/* 左侧滚动栏 */}
                <ColumnUnit
                    customStyleAttr={{"marginRight": "auto"}}
                    sizeAttr={new SizeAttr(wp, leftColWidth + "px", innerPh + "px")}>
                    <RowUnit
                        customStyleAttr={{"minHeight": leftFixedTop + "px"}}
                        sizeAttr={new SizeAttr(wp, leftColWidth + "px", leftFixedTop + "px")}>
                        {
                            selectSource == "recommend" &&
                            <RowUnit
                                customStyleAttr={{"marginBottom": "auto"}}
                                sizeAttr={new SizeAttr(wp, leftColWidth + "px")}>
                                <RowUnit
                                    classname={"lab-panel-container"}
                                    sizeAttr={new SizeAttr(wp, categoryAvailableWidth + "px", categoryItemHeight + "px")}
                                    customStyleAttr={{
                                        "overflowX": "scroll"
                                    }}
                                >
                                    {
                                        categories.map((c) => {
                                            return <CategoryButtonPcAndPad
                                                text={c}
                                                selected={category == c}
                                                width={(103 * bUnit) + "px"}
                                                height={categoryItemHeight + "px"}
                                                buttonClick={() => {
                                                    setCategory(c)
                                                }}
                                            />
                                        })
                                    }
                                </RowUnit>

                                <RowUnit
                                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                        "marginLeft": "auto",
                                    })}
                                >
                                    {SearchBox(wp, searchWidth + "px", keyword,
                                        (v) => {
                                            setKeyword(v)
                                        },
                                        () => {
                                            setActiveKeyword(keyword)
                                        }, searchHeight + "px"
                                    )}
                                </RowUnit>
                            </RowUnit>
                        }
                        {
                            selectSource == "self" &&
                            <RowUnit
                                customStyleAttr={{"marginBottom": "auto", "marginTop": (10 * bUnit) + "px"}}
                                sizeAttr={new SizeAttr(wp, leftColWidth + "px")}>
                                <SpanText
                                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                        "marginRight": "auto"
                                    })}
                                    colorAttr={new ColorAttr(wp, "#ffffff")}
                                    fontAttr={new FontAttr(wp, (24 * bUnit) + "px", "600")}
                                >
                                    {labIndex == "character" ? "My Characters" : "My Templates"}
                                </SpanText>

                                <RowUnit
                                    needHover={true}
                                    onClick={() => {
                                        triggerUpload(labIndex)
                                    }}
                                    sizeAttr={new SizeAttr(wp, (158 * bUnit) + "px", (42 * bUnit) + "px")}
                                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                        "borderRadius": (10 * bUnit) + "px",
                                        "border": "1px solid #FAFAFA"
                                    })}
                                >
                                    <RowUnit customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                                        "transform": "translateY(" + bUnit + "px)"
                                    })}>
                                        <ImageUnit
                                            customStyleAttr={RowAttr.getChildrenPositionObj()}
                                            sizeAttr={new SizeAttr(wp, (28 * bUnit) + "px", (21 * bUnit) + "px")}
                                            url={uploadCloudIcon}/>
                                        <SpanText
                                            customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                                "marginLeft": (10 * bUnit) + "px"
                                            })}
                                            colorAttr={new ColorAttr(wp, "#ffffff")}
                                            fontAttr={new FontAttr(wp, (22 * bUnit) + "px", "500")}
                                        >
                                            {"Upload"}
                                        </SpanText>
                                    </RowUnit>
                                </RowUnit>
                            </RowUnit>
                        }
                    </RowUnit>
                    <CardScrollingPanel
                        key={"CardSelectPopup-CardScrollingPanel" + labIndex + "-" + selectSource + "-" + category + "-" + activeKeyword}
                        labIndex={labIndex}
                        width={leftColWidth + "px"} height={leftScrollHeight + "px"}
                        cardWidth={(201 * bUnit) + "px"} lineCount={4}
                        showDetailCard={(d) => {
                            setDetailData(d)
                        }}
                        dataQueryApi={dataQueryApi}
                        registerDataCallback={registerDataCallback}
                    />
                </ColumnUnit>

                {/* 右侧详情栏 */}
                <BoxUnit
                    sizeAttr={new SizeAttr(wp, rightColWidth + "px", innerPh + "px")}>
                    <ColumnUnit customStyleAttr={BoxAttr.getChildrenPositionObj()}>
                        {
                            selectSource == "recommend" &&
                            <PopupButton
                                wp={wp} width={rightColWidth + "px"} height={(45 * bUnit) + "px"}
                                fontSize={(22 * bUnit) + "px"}
                                text={"Upload"}
                                strokeColor={"#FAFAFA"}
                                fillColor={"#111111"}
                                icon={() => {
                                    return <ImageUnit
                                        customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                            "marginLeft": (8 * bUnit) + "px"
                                        })}
                                        sizeAttr={new SizeAttr(wp, (24 * bUnit) + "px", (18 * bUnit) + "px")}
                                        url={uploadCloudIcon}/>
                                }}
                                click={() => {
                                    triggerUpload(labIndex)
                                }}
                                customStyleAttr={{}}
                            />
                        }
                        <div
                            style={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                "display": "flex",
                                "marginTop": (18 * bUnit) + "px",
                                "marginBottom": (18 * bUnit) + "px",
                                "width": rightColWidth + "px",
                                "height": rightDetailHeight + "px",
                                "borderRadius": mediumRadius,
                                "background": "linear-gradient(to top, #4DD1C11A 0%, #4F78C81A 26%, #8F51B81A 51%, #BE57811A 76%, #BF73531A 100%)",
                                "backgroundClip": "padding-box",
                                "border": "2px solid rgba(255,255,255,0.16)",
                            })}
                        >
                            {
                                labIndex == "template" && detailData &&
                                <video
                                    onContextMenu={(e) => {
                                        e.preventDefault()
                                    }}
                                    controlsList={"nodownload"}
                                    autoPlay={true}
                                    controls={true}
                                    style={Object.assign(BoxAttr.getChildrenPositionObj(), {
                                        "maxWidth": rightColWidth + "px",
                                        "maxHeight": rightDetailHeight + "px"
                                    })}
                                    src={tosLink(detailData.display_video)}/>
                            }
                            {
                                labIndex == "character" && detailData &&
                                <GLBPlayer
                                    key={"CardSelectPopup-RightDetailCol-" + detailData.rig_model}
                                    src={tosLink(detailData.rig_model)}
                                    width={rightColWidth + "px"}
                                    height={rightDetailHeight + "px"}
                                />
                            }
                            {
                                detailData == undefined &&
                                <ColumnUnit customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                                    "transform": "translateY(0px" + ")"
                                })}>
                                    <img
                                        style={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                            "width": (117 * bUnit) + "px",
                                            "height": (117 * bUnit) + "px",
                                            "marginBottom": (12 * bUnit) + "px",
                                        })}
                                        alt={"select"}
                                        src={labIndex == "character" ? selectPopupCharacterIcon : selectPopupTemplateIcon}/>
                                    <SpanText
                                        customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                            "justifyContent": "center",
                                            "textAlign": "center",
                                            "maxWidth": (168 * bUnit) + "px"
                                        })}
                                        colorAttr={new ColorAttr(wp, "#E2E2EAB3")}
                                        fontAttr={new FontAttr(wp, "16px", "500")}
                                    >
                                        {"Select a " + labIndex + " to display"}
                                    </SpanText>
                                </ColumnUnit>
                            }
                        </div>

                        <PopupButton
                            wp={wp} width={rightColWidth + "px"} height={(45 * bUnit) + "px"}
                            fontSize={(22 * bUnit) + "px"}
                            text={"Confirm"}
                            strokeColor={"linear-gradient(to right, #4DD1C19A 0%, #4F78C89A 26%, #8F51B89A 51%, #BE57819A 76%, #BF73539A 100%)"}
                            strokeWidth={"2px"}
                            fillColor={"#111111"}
                            borderRadius={(10 * bUnit) + "px"}
                            click={() => {
                                if (detailData) {
                                    dataConfirm(detailData)
                                    closeClick()
                                }
                            }}
                            customStyleAttr={{"marginRight": "auto"}}
                        />
                    </ColumnUnit>
                </BoxUnit>

            </RowUnit>

        </BoxUnit>
    )
}

export type CardScrollingPanelProps = {
    labIndex: LabIndex,
    width: string
    height: string
    cardWidth: string
    cardGap?: string
    fontSize?: string
    lineCount: number
    showDetailCard: (d: any) => void
    dataQueryApi: (req: {
        page_num: number,
        page_size: number
    }, callback: (success: boolean, resp: any) => void) => void
    registerDataCallback?: (dataCallback: (d: any) => void) => void
}

type CardScrollingPanelState = {
    scrollPosition: number,
    scrollHeight: number,
    isLoading: boolean,
    dataFetched: boolean,
    pageNum: number,
    total: number,
    dataList: any[],
    selectedId: string,
    additionalDataList: any[]
}

export class CardScrollingPanel extends Component<CardScrollingPanelProps> {
    static contextType = WindowParamContext;

    wp: WindowParam = new WindowParam()
    private readonly scrollContainerRef: React.RefObject<HTMLElement>

    constructor(props: any) {
        super(props);
        this.scrollContainerRef = React.createRef<HTMLElement>();
    }

    newDataCallback: (d: any) => void = (d) => {
        // 新上传文件，发起任务，更新状态
        // if (this.props.labIndex == "character") {
        //     console.log("CardScrollingPanel newDataCallback character called")
        //     this.updateAndSaveState({
        //         additionalDataList: [d].concat(this.state.additionalDataList)
        //     })
        // } else if (this.props.labIndex == "template") {
        //     console.log("CardScrollingPanel newDataCallback template called")
        //     this.updateAndSaveState({
        //         additionalDataList: [d].concat(this.state.additionalDataList)
        //     })
        // }
        this.updateAndSaveState({
            scrollPosition: 0,
            scrollHeight: 0,
            isLoading: false,
            dataFetched: false,
            pageNum: 0,
            total: 0,
            dataList: [],
            selectedId: "",
            additionalDataList: []
        }, () => {
            if (this.scrollContainerRef.current != null) {
                this.scrollContainerRef.current.scrollTop = 0;
            }
            this.queryNextPage()
        })
    }

    state: CardScrollingPanelState = {
        scrollPosition: 0,
        scrollHeight: 0,
        isLoading: false,
        dataFetched: false,
        pageNum: 0,
        total: 0,
        dataList: [],
        selectedId: "",
        additionalDataList: []
    }

    // 更新并保存组件状态
    updateAndSaveState(newData: any, callback?: () => void) {
        this.setState(prevState => (Object.assign(prevState,
            newData)), () => {
            if (callback !== undefined) {
                callback()
            }
        })
    }

    componentDidMount() {
        if (this.props.registerDataCallback) {
            this.props.registerDataCallback(this.newDataCallback)
        }
        this.queryNextPage()
    }

    queryNextPage() {
        if (this.state.isLoading) return
        if (this.state.dataFetched && this.state.dataList.length >= this.state.total) {
            return;
        }

        let that = this
        let respCallback = function (success: boolean, resp: any) {
            if (success && resp != undefined) {
                that.updateAndSaveState({
                    "scrollPosition": that.state.pageNum + 1 == 1 ? 0 : that.state.scrollPosition,
                    "dataFetched": true,
                    "isLoading": false,
                    "pageNum": that.state.pageNum + 1,
                    "total": resp.data.total,
                    "dataList": that.state.pageNum + 1 == 1 ? resp.data.list : that.state.dataList.concat(resp.data.list),
                }, () => {
                    if (that.scrollContainerRef.current != null) {
                        that.scrollContainerRef.current.scrollTop = that.state.scrollPosition;
                    }
                })
            } else {
                that.updateAndSaveState({
                    "scrollPosition": that.state.pageNum + 1 == 1 ? 0 : that.state.scrollPosition,
                    "dataFetched": true,
                    "isLoading": false,
                    "pageNum": 0,
                    "total": 0,
                    "dataList": [],
                }, () => {
                    if (that.scrollContainerRef.current != null) {
                        that.scrollContainerRef.current.scrollTop = that.state.scrollPosition;
                    }
                })
            }
        }

        this.setState(prevState => (Object.assign(prevState,
            {
                "isLoading": true
            }) as CardScrollingPanelState), () => {
            this.props.dataQueryApi({
                page_num: this.state.pageNum + 1,
                page_size: this.props.lineCount * 4
            }, respCallback)
        })
    }

    render() {
        return (
            <WindowParamContext.Consumer>
                {wpt => {
                    let wp = wpt.param;
                    let cardAllGap = SizeAttr.pxMinus(this.props.width, SizeAttr.pxMultiple(this.props.cardWidth, this.props.lineCount))
                    let cardGap = this.props.cardGap ? this.props.cardGap : SizeAttr.pxDivide(cardAllGap, this.props.lineCount - 1)

                    let cardFontSize = this.props.fontSize ? this.props.fontSize : "18px"

                    return (
                        <ColumnUnit
                            classname={"card-select-popup-data-panel-" + (this.state.dataList.length) + "-" + (this.state.additionalDataList.length)}
                            onScroll={(e) => {
                                let target = e.target as HTMLDivElement
                                if (target) {
                                    let activeScrollTop = target.scrollTop
                                    this.setState(prevState => (Object.assign(prevState,
                                        {
                                            "scrollPosition": activeScrollTop,
                                            "scrollHeight": target.scrollHeight
                                        })))
                                    if (activeScrollTop == 0) {
                                        this.updateAndSaveState({
                                            "dataFetched": false,
                                            "pageNum": 0,
                                            "pageSize": this.props.lineCount * 4,
                                            "total": 0
                                        }, () => {
                                            this.queryNextPage()
                                        })
                                    } else if (activeScrollTop >= target.scrollHeight - wp.currHeight) {
                                        this.queryNextPage()
                                    }
                                }
                            }}
                            unitRef={this.scrollContainerRef}
                            customStyleAttr={{"overflowY": "auto"}}
                            sizeAttr={new SizeAttr(wp, this.props.width, this.props.height)}>

                            {
                                splitArrayIntoGroups(this.state.additionalDataList.concat(this.state.dataList),
                                    this.props.lineCount).map((rowDataList, idx) => {
                                    return (
                                        <RowUnit
                                            key={"CardSelectPopup-CardScrollingPanel-" + this.props.labIndex + "-" + idx}
                                            customStyleAttr={{
                                                "marginTop": smallPadding,
                                                "marginBottom": smallPadding,
                                                "marginRight": "auto"
                                            }}
                                        >
                                            {
                                                this.props.labIndex == "character" &&
                                                rowDataList.map((rd, rIdx) => {
                                                    return <TcCharacterCard
                                                        wp={wp}
                                                        width={this.props.cardWidth}
                                                        fontSize={cardFontSize}
                                                        marginRight={rIdx == this.props.lineCount - 1 ? "" : cardGap}
                                                        tcCvCharactersData={rd}
                                                        selected={rd.id != "0" && this.state.selectedId == rd.id}
                                                        onClick={(d) => {
                                                            this.updateAndSaveState({selectedId: d.id})
                                                            this.props.showDetailCard(d)
                                                        }}
                                                        mouseEnterCallback={(d) => {
                                                            this.updateAndSaveState({selectedId: d.id})
                                                        }}
                                                    />
                                                })
                                            }
                                            {
                                                this.props.labIndex == "template" &&
                                                rowDataList.map((rd, rIdx) => {
                                                    return <TcTemplateCard
                                                        wp={wp}
                                                        width={this.props.cardWidth}
                                                        fontSize={cardFontSize}
                                                        marginRight={rIdx == this.props.lineCount - 1 ? "" : cardGap}
                                                        tcCvTemplatesData={rd}
                                                        selected={rd.id != "0" && this.state.selectedId == rd.id}
                                                        onClick={(d) => {
                                                            this.updateAndSaveState({selectedId: d.id})
                                                            this.props.showDetailCard(d)
                                                        }}
                                                        mouseEnterCallback={(d) => {
                                                            this.updateAndSaveState({selectedId: d.id})
                                                        }}
                                                    />
                                                })
                                            }
                                            {
                                                this.props.labIndex == "mixVideo" &&
                                                rowDataList.map((rd, rIdx) => {
                                                    return <TcMixVideoCard
                                                        wp={wp}
                                                        width={this.props.cardWidth}
                                                        fontSize={cardFontSize}
                                                        marginRight={rIdx == this.props.lineCount - 1 ? "" : cardGap}
                                                        tcCvMixVideosData={rd}
                                                        selected={rd.id != "0" && this.state.selectedId == rd.id}
                                                        onClick={(d) => {
                                                            this.updateAndSaveState({selectedId: d.id})
                                                            this.props.showDetailCard(d)
                                                        }}
                                                        mouseEnterCallback={(d) => {
                                                            this.updateAndSaveState({selectedId: d.id})
                                                        }}
                                                    />
                                                })
                                            }
                                        </RowUnit>
                                    )
                                })
                            }

                            <RowUnit sizeAttr={new SizeAttr(wp, this.props.width,
                                this.state.pageNum == 0 ? this.props.height : "64px")}>
                                {
                                    this.state.isLoading &&
                                    <LoadingOutlined
                                        size={48}
                                        style={BoxAttr.getChildrenPositionObj()}/>
                                }
                            </RowUnit>
                        </ColumnUnit>
                    )
                }
                }
            </WindowParamContext.Consumer>
        )
    }
}