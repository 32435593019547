import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {WindowParamContext, WindowParamContextProvider} from './library/context/WindowContext';
import {TcCvPromptPage} from "./pages/admin/TcCvPromptPage";
import {TcCvRedirectPage} from "./pages/admin/TcCvRedirectPage";
import {TcCvMainPage} from "./pages/admin/TcCvMainPage";
import {TcCvLoginPage} from "./pages/admin/TcCvLoginPage";


function App() {
    return (
        <WindowParamContextProvider>
            <WindowParamContext.Consumer>
                {wpt => {
                    let wp = wpt.param;
                    return (
                        <Router basename='/'>
                            <Routes key={window.location.pathname} location={window.location}>
                                <Route path="/prompt" element={<TcCvRedirectPage>
                                </TcCvRedirectPage>}></Route>
                                <Route path="/prompt/template" element={<TcCvPromptPage
                                    key={"TcCvPromptPage" + wp.version}></TcCvPromptPage>}></Route>
                                <Route path="/prompt/character" element={<TcCvPromptPage
                                    key={"TcCvPromptPage" + wp.version}></TcCvPromptPage>}></Route>
                                <Route path="/login" element={<TcCvLoginPage
                                    key={"TcCvLoginPage" + wp.version}></TcCvLoginPage>}></Route>
                                <Route path="/" element={<TcCvMainPage
                                    key={"TcCvHomePage" + wp.version}></TcCvMainPage>}></Route>
                                <Route path="/home" element={<TcCvMainPage
                                    key={"TcCvHomePage" + wp.version}></TcCvMainPage>}></Route>
                                <Route path="/mine" element={<TcCvMainPage
                                    key={"TcCvMinePage" + wp.version}></TcCvMainPage>}></Route>
                                <Route path="/character" element={<TcCvMainPage
                                    key={"TcCvMinePage" + wp.version}></TcCvMainPage>}></Route>
                                <Route path="/template" element={<TcCvMainPage
                                    key={"TcCvMinePage" + wp.version}></TcCvMainPage>}></Route>
                            </Routes>
                        </Router>
                    )
                }
                }
            </WindowParamContext.Consumer>
        </WindowParamContextProvider>
    );
}

export default App;
