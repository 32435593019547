import {WindowParam, WindowParamContext} from "../../../../library/context/WindowContext";
import * as React from "react";
import {Component, FC, useEffect, useState} from "react";
import {ColumnUnit} from "../../../../library/atomic/unit/ColumnUnit";
import {RowUnit} from "../../../../library/atomic/unit/RowUnit";
import {smallPadding, smallRadius} from "../../display/layout";
import {SizeAttr} from "../../../../library/basic/size";
import {BoxAttr, ColumnAttr, RowAttr} from "../../../../library/basic/compose";
import {SpanText} from "../../../../library/atomic/text/SpanText";
import {ColorAttr} from "../../../../library/basic/color";
import {FontAttr} from "../../../../library/basic/font";
import {BoxUnit} from "../../../../library/atomic/unit/BoxUnit";
import {
    queryTcCvTemplatesByPage,
    queryUserCvTemplatesByPage,
    TcCvTemplateCategoryList,
    TcCvTemplatesData,
    TcCvTemplatesPaginationResp
} from "../../data/TcCvTemplates";
import {splitArrayIntoGroups} from "../../../../library/util/arrayUtil";
import {TcTemplateCard} from "../PagingCard";
import {LoadingOutlined} from "@ant-design/icons";
import {tosLink} from "../../../../library/util/tosUtil";
import "./TcCvTemplatePanel.css"
import {CategoryButtonPcAndPad} from "../../panel/CategoryButtonPcAndPad";
import {SearchBox} from "../../panel/SearchBox";
import uploadPlusIcon from "../../../../assets/user/panel/upload_plus_icon.svg";
import {ImageUnit} from "../../../../library/atomic/image/ImageUnit";

interface TcCvTemplatePanelProps {
    wp: WindowParam
    width: string
    height: string
    showTemplateDetailCard: (d: TcCvTemplatesData) => void
    triggerUpload: () => void
}

export const TcCvTemplatePanel: FC<TcCvTemplatePanelProps> = ({
                                                                  wp,
                                                                  width,
                                                                  height,
                                                                  showTemplateDetailCard,
                                                                  triggerUpload
                                                              }: TcCvTemplatePanelProps) => {
    let widthNum = SizeAttr.getPxNumber(width);
    let innerColWidthNum = widthNum - 32 * 2

    return (
        <ColumnUnit sizeAttr={new SizeAttr(wp, width, height)}>
            <TcCvTemplateInnerPanel
                width={innerColWidthNum + "px"}
                height={height}
                showTemplateDetailCard={showTemplateDetailCard}
                triggerUpload={triggerUpload}/>
        </ColumnUnit>
    )
}

interface TcCvMyTemplateLineProps {
    wp: WindowParam
    count: number
    width: string
    height: string
    gap: string
    showTemplateDetailCard: (d: TcCvTemplatesData) => void
    triggerUpload: () => void
}

const TcCvMyTemplateLine: FC<TcCvMyTemplateLineProps> = ({
                                                             wp,
                                                             count,
                                                             width,
                                                             height,
                                                             gap,
                                                             showTemplateDetailCard,
                                                             triggerUpload
                                                         }: TcCvMyTemplateLineProps) => {
    const [templates, setTemplates] = useState<TcCvTemplatesData[]>([]);

    useEffect(() => {
        queryUserCvTemplatesByPage({page_num: 1, page_size: count}, (success, resp) => {
            if (success && resp) {
                // 过滤生成中的角色
                let res: TcCvTemplatesData[] = []
                for (let i = 0; i < resp.data.list.length; i++) {
                    if (resp.data.list[i].id != "" && resp.data.list[i].id != "0") {
                        res = res.concat(resp.data.list[i])
                    }
                }
                setTemplates(res)
            }
        })
    }, []);

    return (
        <RowUnit
            classname={"template-panel-template-line"}
            customStyleAttr={{
                "minHeight": height,
                "overflowX": "scroll"
            }}
            sizeAttr={new SizeAttr(wp, width, height)}>
            <BoxUnit
                needHover={true}
                onClick={() => {
                    triggerUpload()
                }}
                key={"TcCvTemplatePanel-TcCvMyTemplateLine-Template-Upload"}
                sizeAttr={new SizeAttr(wp, SizeAttr.pxMinus(height, "2px"), SizeAttr.pxMinus(height, "2px"))}
                customStyleAttr={{
                    "marginRight": gap,
                    "borderRadius": smallRadius,
                    "border": "1px solid #6D6D6D",
                    "minWidth": SizeAttr.pxMinus(height, "2px")
                }}
            >
                <ColumnUnit customStyleAttr={Object.assign(BoxAttr.getChildrenPositionObj(), {
                    "transform": "translateY(4px)"
                })}>
                    <ImageUnit
                        customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                            "marginBottom": "8px"
                        })}
                        sizeAttr={new SizeAttr(wp, "28.5px", "28.5px")}
                        url={uploadPlusIcon}/>
                    <SpanText
                        colorAttr={new ColorAttr(wp, "#ffffff")}
                        fontAttr={new FontAttr(wp, "12px", "600")}
                        customStyleAttr={ColumnAttr.getChildrenPositionObj()}>
                        {"Upload"}
                    </SpanText>
                </ColumnUnit>
            </BoxUnit>
            {
                templates.map((c, idx) => {
                    return (
                        <BoxUnit
                            needHover={true}
                            onClick={() => {
                                showTemplateDetailCard(c)
                            }}
                            key={"TcCvTemplatePanel-TcCvMyTemplateLine-Template-" + c.id + "-" + idx}
                            sizeAttr={new SizeAttr(wp, height, height)}
                            customStyleAttr={{
                                "marginRight": idx == templates.length - 1 ? "0px" : gap,
                                "borderRadius": smallRadius,
                                "background": "#141415",
                                "overflow": "hidden",
                                "minWidth": height
                            }}
                        >
                            <img
                                style={{
                                    width: height,
                                    height: height,
                                    borderRadius: smallRadius,
                                    objectFit: 'cover',
                                    minWidth: height
                                }}
                                alt={""}
                                src={tosLink(c.cover) + "!h400"}/>
                        </BoxUnit>
                    )
                })
            }
        </RowUnit>
    )
}

interface TcCvTemplateInnerPanelProps {
    width: string
    height: string
    showTemplateDetailCard: (d: TcCvTemplatesData) => void
    triggerUpload: () => void
}

type TcCvTemplateInnerPanelState = {
    currentCategory: string
    searchKeyword: string
    scrollPosition: number,
    scrollHeight: number,
    isLoading: boolean,
    dataFetched: boolean,
    pageNum: number,
    total: number,
    dataList: TcCvTemplatesData[],
    selectedId: string
    lineCount: number
    cardWidthNum: number
}

class TcCvTemplateInnerPanel extends Component<TcCvTemplateInnerPanelProps> {
    static contextType = WindowParamContext;

    wp: WindowParam = new WindowParam()
    private readonly scrollContainerRef: React.RefObject<HTMLElement>

    constructor(props: any) {
        super(props);
        this.scrollContainerRef = React.createRef<HTMLElement>();
    }

    state: TcCvTemplateInnerPanelState = {
        currentCategory: "ALL",
        searchKeyword: "",
        scrollPosition: 0,
        scrollHeight: 0,
        isLoading: false,
        dataFetched: false,
        pageNum: 0,
        total: 0,
        dataList: [],
        selectedId: "",
        lineCount: 6,
        cardWidthNum: 0
    }

    // 更新并保存组件状态
    updateAndSaveState(newData: any, callback?: () => void) {
        this.setState(prevState => (Object.assign(prevState,
            newData)), () => {
            if (callback !== undefined) {
                callback()
            }
        })
    }

    componentDidMount() {
        let widthNum = SizeAttr.getPxNumber(this.props.width)
        let cardGap = 16
        let lineCount = 5
        let cardWidthNum = (widthNum - cardGap * (lineCount - 1)) / lineCount

        this.updateAndSaveState({lineCount: lineCount, cardWidthNum: cardWidthNum}, () => {
            this.queryNextPage()
        })
    }

    queryNextPage() {
        if (this.state.isLoading) return
        if (this.state.dataFetched && this.state.dataList.length >= this.state.total) {
            return;
        }

        let that = this
        let respCallback = function (success: boolean, resp: TcCvTemplatesPaginationResp | undefined) {
            if (success && resp != undefined) {
                that.updateAndSaveState({
                    "scrollPosition": that.state.pageNum + 1 == 1 ? 0 : that.state.scrollPosition,
                    "dataFetched": true,
                    "isLoading": false,
                    "pageNum": that.state.pageNum + 1,
                    "total": resp.data.total,
                    "dataList": that.state.pageNum + 1 == 1 ? resp.data.list : that.state.dataList.concat(resp.data.list),
                }, () => {
                    if (that.scrollContainerRef.current != null) {
                        that.scrollContainerRef.current.scrollTop = that.state.scrollPosition;
                    }
                })
            } else {
                that.updateAndSaveState({
                    "scrollPosition": that.state.pageNum + 1 == 1 ? 0 : that.state.scrollPosition,
                    "dataFetched": true,
                    "isLoading": false,
                    "pageNum": 0,
                    "total": 0,
                    "dataList": [],
                }, () => {
                    if (that.scrollContainerRef.current != null) {
                        that.scrollContainerRef.current.scrollTop = that.state.scrollPosition;
                    }
                })
            }
        }

        this.setState(prevState => (Object.assign(prevState,
            {
                "isLoading": true
            }) as TcCvTemplateInnerPanelState), () => {
            queryTcCvTemplatesByPage({
                category: this.state.currentCategory,
                keyword: this.state.searchKeyword,
                page_num: this.state.pageNum + 1,
                page_size: this.state.lineCount * 4
            }, (success, resp) => {
                respCallback(success, resp)
            })
        })
    }

    triggerSearch() {
        this.setState(prevState => (Object.assign(prevState,
            {
                isLoading: false,
                dataFetched: false,
                pageNum: 0,
                total: 0,
                dataList: [],
                selectedId: ""
            })), () => {
            this.queryNextPage()
        })
    }

    render() {
        return (
            <WindowParamContext.Consumer>
                {wpt => {
                    let wp = wpt.param;

                    let textMargin = 16
                    let templateLineMarginBottom = 32

                    let cardGap = 16

                    // 顶部标签和搜索元素高度
                    let searchBoxWidth = "210px"
                    let categoryButtonRowWidth = SizeAttr.pxMinus(this.props.width, (210 + 32) + "px")
                    let categoryRowHeight = "32px"
                    let categoryRowTopMargin = "4px"
                    let categoryRowBottomMargin = "12px"

                    return (
                        <ColumnUnit
                            classname={"template-panel-template-line"}
                            onScroll={(e) => {
                                let target = e.target as HTMLDivElement
                                if (target) {
                                    let activeScrollTop = target.scrollTop
                                    this.setState(prevState => (Object.assign(prevState,
                                        {
                                            "scrollPosition": activeScrollTop,
                                            "scrollHeight": target.scrollHeight
                                        })))
                                    if (activeScrollTop == 0) {
                                        this.updateAndSaveState({
                                            "dataFetched": false,
                                            "pageNum": 0,
                                            "pageSize": this.state.lineCount * 2,
                                            "total": 0
                                        }, () => {
                                            this.queryNextPage()
                                        })
                                    } else if (activeScrollTop >= target.scrollHeight - wp.currHeight) {
                                        this.queryNextPage()
                                    }
                                }
                            }}
                            unitRef={this.scrollContainerRef}
                            customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {"overflowY": "auto"})}
                            sizeAttr={new SizeAttr(wp, this.props.width, this.props.height)}>

                            <SpanText
                                colorAttr={new ColorAttr(wp, "#E2E2EA")}
                                customStyleAttr={{
                                    "justifyContent": "",
                                    "marginTop": textMargin + "px",
                                    "marginBottom": textMargin + "px"
                                }}
                                fontAttr={new FontAttr(wp, "18px", "600")}
                            >
                                {"My Templates"}
                            </SpanText>

                            <TcCvMyTemplateLine
                                wp={wp} count={20} width={this.props.width}
                                height={"120px"} gap={"16px"}
                                showTemplateDetailCard={this.props.showTemplateDetailCard}
                                triggerUpload={this.props.triggerUpload}/>

                            <SpanText
                                colorAttr={new ColorAttr(wp, "#E2E2EA")}
                                customStyleAttr={{
                                    "justifyContent": "",
                                    "marginTop": templateLineMarginBottom + "px",
                                    "marginBottom": smallPadding
                                }}
                                fontAttr={new FontAttr(wp, "18px", "600")}
                            >
                                {"Public Templates"}
                            </SpanText>

                            {/* 顶部标签和搜索 */}
                            <RowUnit
                                customStyleAttr={{
                                    "marginTop": categoryRowTopMargin,
                                    "marginBottom": categoryRowBottomMargin
                                }}
                                sizeAttr={new SizeAttr(wp, this.props.width, categoryRowHeight)}
                            >
                                <RowUnit
                                    classname={"lab-panel-container"}
                                    sizeAttr={new SizeAttr(wp, categoryButtonRowWidth, categoryRowHeight)}
                                    customStyleAttr={{
                                        "marginRight": "auto",
                                        "overflowX": "scroll"
                                    }}
                                >
                                    {
                                        ["ALL"].concat(TcCvTemplateCategoryList).map((c) => {
                                            return <CategoryButtonPcAndPad
                                                text={c}
                                                selected={this.state.currentCategory == c}
                                                buttonClick={() => {
                                                    this.updateAndSaveState({
                                                        currentCategory: c,
                                                        currentPageNum: 1
                                                    }, () => {
                                                        this.triggerSearch()
                                                    })
                                                }}
                                            />
                                        })
                                    }
                                </RowUnit>
                                <RowUnit
                                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                        "marginRight": "0px",
                                    })}
                                >
                                    {SearchBox(wp, searchBoxWidth, this.state.searchKeyword,
                                        (v) => {
                                            this.updateAndSaveState({searchKeyword: v})
                                        },
                                        () => {
                                            this.updateAndSaveState({currentPageNum: 1}, () => {
                                                this.triggerSearch()
                                            })
                                        }
                                    )}
                                </RowUnit>
                            </RowUnit>

                            {
                                this.state.dataList.length >= 0 &&
                                splitArrayIntoGroups(this.state.dataList, this.state.lineCount).map((rowDataList, idx) => {
                                    return (
                                        <RowUnit
                                            key={"TcCvHomePanel-TcCvTemplatePanel-" + idx}
                                            customStyleAttr={{
                                                "marginTop": smallPadding,
                                                "marginBottom": smallPadding,
                                                "marginRight": "auto"
                                            }}
                                        >
                                            {
                                                rowDataList.map((rd, rIdx) => {
                                                    return <TcTemplateCard
                                                        wp={wp}
                                                        width={this.state.cardWidthNum + "px"}
                                                        fontSize={"18px"}
                                                        marginRight={rIdx == this.state.lineCount - 1 ? "" : cardGap + "px"}
                                                        tcCvTemplatesData={rd}
                                                        selected={rd.id != "0" && this.state.selectedId == rd.id}
                                                        onClick={(d) => {
                                                            this.updateAndSaveState({selectedId: d.id})
                                                            this.props.showTemplateDetailCard(d)
                                                        }}
                                                        mouseEnterCallback={(d) => {
                                                            this.updateAndSaveState({selectedId: d.id})
                                                        }}
                                                    />
                                                })
                                            }
                                        </RowUnit>
                                    )
                                })
                            }

                            <RowUnit sizeAttr={new SizeAttr(wp, this.props.width, "64px")}>
                                {
                                    this.state.isLoading && this.state.pageNum > 0 &&
                                    <LoadingOutlined
                                        size={48}
                                        style={BoxAttr.getChildrenPositionObj()}/>
                                }
                            </RowUnit>
                        </ColumnUnit>
                    )
                }
                }
            </WindowParamContext.Consumer>
        )
    }
}
