import {WindowParam, WindowParamContext} from "../../../../library/context/WindowContext";
import {queryTcCvMixVideosByPage, TcCvMixVideosData, TcCvMixVideosPaginationResp} from "../../data/TcCvMixVideos";
import {queryTcCvCharactersByPage, TcCvCharactersData} from "../../data/TcCvCharacters";
import {TcCvTemplatesData} from "../../data/TcCvTemplates";
import {Component, FC, useEffect, useState} from "react";
import {SizeAttr} from "../../../../library/basic/size";
import {ColumnUnit} from "../../../../library/atomic/unit/ColumnUnit";
import {BoxAttr, ColumnAttr, RowAttr} from "../../../../library/basic/compose";
import * as React from "react";
import {RowUnit} from "../../../../library/atomic/unit/RowUnit";
import {BoxUnit} from "../../../../library/atomic/unit/BoxUnit";
import {tosLink} from "../../../../library/util/tosUtil";
import {ImageUnit} from "../../../../library/atomic/image/ImageUnit";
import {smallPadding} from "../../display/layout";
import {SpanText} from "../../../../library/atomic/text/SpanText";
import {cutStr} from "../../../../library/util/stringUtil";
import {ColorAttr} from "../../../../library/basic/color";
import {FontAttr} from "../../../../library/basic/font";
import {splitArrayIntoGroups} from "../../../../library/util/arrayUtil";
import {TcMixVideoCard} from "../PagingCard";
import {LoadingOutlined} from "@ant-design/icons";
import "./TcCvHomePanel.css"
import {SearchBox} from "../../panel/SearchBox";

interface TcCvUserHomePanelPhoneProps {
    wp: WindowParam
    width: string
    height: string
    showMixVideoDetailCard: (d: TcCvMixVideosData) => void
    showCharacterDetailCard: (d: TcCvCharactersData) => void
    showTemplateDetailCard: (d: TcCvTemplatesData) => void
}


type TcCvUserHomeMixVideosPanelPhoneState = {
    searchKeyword: string,
    characterList: TcCvCharactersData[],
    scrollPosition: number,
    scrollHeight: number,
    isLoading: boolean,
    dataFetched: boolean,
    pageNum: number,
    total: number,
    dataList: TcCvMixVideosData[],
    selectedId: string,
    lineCount: number,
    cardWidthNum: number
}

export class TcCvUserHomePanelPhone extends Component<TcCvUserHomePanelPhoneProps> {
    static contextType = WindowParamContext;

    wp: WindowParam = new WindowParam()
    private readonly scrollContainerRef: React.RefObject<HTMLElement>

    constructor(props: any) {
        super(props);
        this.scrollContainerRef = React.createRef<HTMLElement>();
    }

    state: TcCvUserHomeMixVideosPanelPhoneState = {
        searchKeyword: "",
        characterList: [],
        scrollPosition: 0,
        scrollHeight: 0,
        isLoading: false,
        dataFetched: false,
        pageNum: 0,
        total: 0,
        dataList: [],
        selectedId: "",
        lineCount: 2,
        cardWidthNum: 0
    }

    // 更新并保存组件状态
    updateAndSaveState(newData: any, callback?: () => void) {
        this.setState(prevState => (Object.assign(prevState,
            newData)), () => {
            if (callback !== undefined) {
                callback()
            }
        })
    }

    componentDidMount() {
        let widthNum = SizeAttr.getPxNumber(this.props.width)
        let rowBlank = 32
        let cardGap = 8
        let lineCount = 2
        let cardWidthNum = (widthNum - rowBlank - cardGap * (lineCount - 1)) / lineCount

        this.updateAndSaveState({lineCount: lineCount, cardWidthNum: cardWidthNum}, () => {
            queryTcCvCharactersByPage({category: "", keyword: "", page_num: 1, page_size: 4}, (success, resp) => {
                if (success && resp) {
                    this.updateAndSaveState({
                        characterList: resp.data.list
                    })
                }
            })
            this.queryNextPage()
        })
    }

    triggerSearch() {
        this.setState(prevState => (Object.assign(prevState,
            {
                scrollPosition: 0,
                scrollHeight: 0,
                isLoading: false,
                dataFetched: false,
                pageNum: 0,
                total: 0,
                dataList: []
            })), () => {
            if (this.scrollContainerRef.current != null) {
                this.scrollContainerRef.current.scrollTop = this.state.scrollPosition;
            }
            this.queryNextPage()
        })
    }

    queryNextPage() {
        if (this.state.isLoading) return
        if (this.state.dataFetched && this.state.dataList.length >= this.state.total) {
            return;
        }

        let that = this
        let respCallback = function (success: boolean, resp: TcCvMixVideosPaginationResp | undefined) {
            if (success && resp != undefined) {
                that.updateAndSaveState({
                    "scrollPosition": that.state.pageNum + 1 == 1 ? 0 : that.state.scrollPosition,
                    "dataFetched": true,
                    "isLoading": false,
                    "pageNum": that.state.pageNum + 1,
                    "total": resp.data.total,
                    "dataList": that.state.pageNum + 1 == 1 ? resp.data.list : that.state.dataList.concat(resp.data.list),
                }, () => {
                    if (that.scrollContainerRef.current != null) {
                        that.scrollContainerRef.current.scrollTop = that.state.scrollPosition;
                    }
                })
            } else {
                that.updateAndSaveState({
                    "scrollPosition": that.state.pageNum + 1 == 1 ? 0 : that.state.scrollPosition,
                    "dataFetched": true,
                    "isLoading": false,
                    "pageNum": 0,
                    "total": 0,
                    "dataList": [],
                }, () => {
                    if (that.scrollContainerRef.current != null) {
                        that.scrollContainerRef.current.scrollTop = that.state.scrollPosition;
                    }
                })
            }
        }

        this.setState(prevState => (Object.assign(prevState,
            {
                "isLoading": true
            }) as TcCvUserHomeMixVideosPanelPhoneState), () => {
            queryTcCvMixVideosByPage({
                keyword: this.state.searchKeyword,
                page_num: this.state.pageNum + 1,
                page_size: this.state.lineCount * 4
            }, (success, resp) => {
                respCallback(success, resp)
            })
        })
    }

    render() {
        return (
            <WindowParamContext.Consumer>
                {wpt => {
                    let wp = wpt.param;

                    let widthNum = SizeAttr.getPxNumber(this.props.width);
                    let heightNum = SizeAttr.getPxNumber(this.props.height)
                    let innerColWidthNum = widthNum - 16 * 2
                    let characterInnerSize = (innerColWidthNum - 24) / 4
                    let characterLineHeight = characterInnerSize + 60
                    let searchBoxLine = 32 + 8 * 2

                    let rowBlank = 32
                    let cardGap = 8
                    let lineCount = 2
                    let cardWidthNum = (widthNum - rowBlank - cardGap * (lineCount - 1)) / lineCount

                    return (
                        <ColumnUnit
                            classname={"home-panel-character-line"}
                            onScroll={(e) => {
                                let target = e.target as HTMLDivElement
                                if (target) {
                                    let activeScrollTop = target.scrollTop
                                    this.setState(prevState => (Object.assign(prevState,
                                        {
                                            "scrollPosition": activeScrollTop,
                                            "scrollHeight": target.scrollHeight
                                        })))
                                    if (activeScrollTop == 0) {
                                        this.updateAndSaveState({
                                            "dataFetched": false,
                                            "pageNum": 0,
                                            "pageSize": this.state.lineCount * 2,
                                            "total": 0
                                        }, () => {
                                            this.queryNextPage()
                                        })
                                    } else if (activeScrollTop >= target.scrollHeight - wp.currHeight) {
                                        this.queryNextPage()
                                    }
                                }
                            }}
                            unitRef={this.scrollContainerRef}
                            customStyleAttr={{"overflowY": "auto"}}
                            sizeAttr={new SizeAttr(wp, this.props.width, this.props.height)}>

                            <ColumnUnit
                                customStyleAttr={ColumnAttr.getChildrenPositionObj()}
                                sizeAttr={new SizeAttr(wp, innerColWidthNum + "px")}>

                                {/*角色栏*/}
                                <RowUnit sizeAttr={new SizeAttr(wp, innerColWidthNum + "px", characterLineHeight + "px")}>
                                    {
                                        this.state.characterList.map((c, idx) => {
                                            return (
                                                <ColumnUnit
                                                    needHover={true}
                                                    onClick={() => {
                                                        this.props.showCharacterDetailCard(c)
                                                    }}
                                                    customStyleAttr={Object.assign(RowAttr.getChildrenPositionObj(), {
                                                        "marginRight": idx == 3 ? "0px" : smallPadding
                                                    })}
                                                    sizeAttr={new SizeAttr(wp, characterInnerSize + "px")}
                                                >
                                                    <BoxUnit
                                                        customStyleAttr={Object.assign(ColumnAttr.getChildrenPositionObj(), {
                                                            "borderRadius": (characterInnerSize / 2) + "px",
                                                            "marginBottom": "6px",
                                                            "overflow": "hidden",
                                                            "background": "#2C2C2C"
                                                        })}
                                                        sizeAttr={new SizeAttr(wp, characterInnerSize + "px", characterInnerSize + "px")}
                                                    >
                                                        <img
                                                            alt={""}
                                                            style={{
                                                                "width": characterInnerSize + "px",
                                                                "height": characterInnerSize + "px"
                                                            }}
                                                            src={tosLink(c.cover + "!ccover")}
                                                        />
                                                    </BoxUnit>

                                                    <SpanText
                                                        customStyleAttr={ColumnAttr.getChildrenPositionObj()}
                                                        fontAttr={new FontAttr(wp, "12px", "600")}
                                                        colorAttr={new ColorAttr(wp, "#e3e3e3")}
                                                    >
                                                        {"@ " + cutStr(c.name, "Panda_Girl".length)}
                                                    </SpanText>
                                                </ColumnUnit>
                                            )
                                        })
                                    }
                                </RowUnit>

                                <BoxUnit
                                    sizeAttr={new SizeAttr(wp, innerColWidthNum + "px", searchBoxLine + "px")}
                                    customStyleAttr={ColumnAttr.getChildrenPositionObj()}>
                                    {SearchBox(wp, innerColWidthNum + "px", this.state.searchKeyword,
                                        (v) => {
                                            this.updateAndSaveState({searchKeyword: v})
                                        },
                                        () => {
                                            this.triggerSearch()
                                        }
                                    )}
                                </BoxUnit>

                                {
                                    this.state.dataList.length >= 0 &&
                                    splitArrayIntoGroups(this.state.dataList, this.state.lineCount).map((rowDataList, idx) => {
                                        return (
                                            <RowUnit
                                                key={"TcCvHomePanelPhone-TcCvUserHomePhoneMixVideosPanel-" + idx}
                                                customStyleAttr={{
                                                    "marginTop": smallPadding,
                                                    "marginBottom": smallPadding,
                                                    "marginRight": "auto"
                                                }}
                                            >
                                                {
                                                    rowDataList.map((rd, rIdx) => {
                                                        return <TcMixVideoCard
                                                            wp={wp}
                                                            width={this.state.cardWidthNum + "px"}
                                                            fontSize={"18px"}
                                                            marginRight={rIdx == this.state.lineCount - 1 ? "" : cardGap + "px"}
                                                            tcCvMixVideosData={rd}
                                                            selected={rd.id != "0" && this.state.selectedId == rd.id}
                                                            onClick={(d) => {
                                                                this.updateAndSaveState({selectedId: d.id})
                                                                this.props.showMixVideoDetailCard(d)
                                                            }}
                                                            mouseEnterCallback={(d) => {
                                                                this.updateAndSaveState({selectedId: d.id})
                                                            }}
                                                        />
                                                    })
                                                }
                                            </RowUnit>
                                        )
                                    })
                                }

                                <RowUnit sizeAttr={new SizeAttr(wp, innerColWidthNum + "px", "64px")}>
                                    {
                                        this.state.isLoading && this.state.pageNum > 0 &&
                                        <LoadingOutlined
                                            size={48}
                                            style={BoxAttr.getChildrenPositionObj()}/>
                                    }
                                </RowUnit>
                            </ColumnUnit>
                        </ColumnUnit>
                    )
                }
                }
            </WindowParamContext.Consumer>
        )
    }
}