import {TC_AIUNI_CV_BACKEND_URL} from "../../../library/util/apiUtil";
import {getToken} from "../util/account";
import {emptyJobData, TcCvJobsData} from "./TcCvJob";
import {encodeId} from "../util/id";
import {v4 as uuidv4} from "uuid";
import {emptyUserDb, UserDb} from "./TcCvUser";

export const TcCvCharacterCategoryList = ["Animation", "Game", "Film", "Design", "Robot", "Anime", "MEME", "Realistic"]

export type TcCvCharactersData = {
    id: string
    name: string
    cover: string
    org_model: string
    rig_model: string
    category: string
    tags: string
    job: TcCvJobsData
    user: UserDb
    file?: File
    fileUuid?: string
}

export function getInitCharacterDataFromFile(file: File): TcCvCharactersData {
    return {
        id: "",
        name: "",
        cover: "",
        org_model: "",
        rig_model: "",
        category: "",
        tags: "",
        job: emptyJobData(),
        user: emptyUserDb(),
        file: file,
        fileUuid: uuidv4()
    }
}

export type TcCvCharactersPaginationData = {
    total: number
    list: TcCvCharactersData[]
}

export type TcCvCharactersPaginationResp = {
    code: number
    msg: string
    data: TcCvCharactersPaginationData
}

export type TcCvCharactersListResp = {
    code: number
    msg: string
    data: TcCvCharactersData[]
}

export type QueryTcCvCharactersByPageReq = {
    category: string
    keyword: string
    page_num: number
    page_size: number
}

export function queryTcCvCharactersByPage(req: QueryTcCvCharactersByPageReq, callback: (success: boolean, resp?: TcCvCharactersPaginationResp) => void) {
    let innerReq: QueryTcCvCharactersByPageReq = {
        category: req.category == "ALL" ? "" : req.category,
        keyword: req.keyword,
        page_num: req.page_num,
        page_size: req.page_size
    }

    fetch(TC_AIUNI_CV_BACKEND_URL + "/recommendCvCharactersByPage" + "?page_num=" + innerReq.page_num + "&page_size="
        + innerReq.page_size + "&" + "category=" + innerReq.category + "&" + "keyword=" + innerReq.keyword)
        .then(response => response.json())
        .then(res => {
            try {
                let resp = res as TcCvCharactersPaginationResp;
                callback(resp.code === 200, resp);
            } catch (e) {
                callback(false, undefined);
            }
        })
}

export type QueryUserCvCharactersByPageReq = {
    page_num: number
    page_size: number
}

export function queryUserCvCharactersByPage(req: QueryUserCvCharactersByPageReq, callback: (success: boolean, resp?: TcCvCharactersPaginationResp) => void) {
    const options: RequestInit = {
        method: 'GET',
        headers: {
            'Authorization': getToken()
        },
    };

    fetch(TC_AIUNI_CV_BACKEND_URL + "/queryUserCvCharactersWithJobs?limit=100", options)
        .then(response => response.json())
        .then(res => {
            try {
                let resp = res as TcCvCharactersListResp;
                callback(resp.code === 200, {
                    code: 200,
                    msg: resp.msg,
                    data: {
                        total: resp.data.length,
                        list: resp.data
                    }
                });
            } catch (e) {
                callback(false, undefined);
            }
        })
}

export type TcCvCharactersResp = {
    code: number
    msg: string
    data: TcCvCharactersData
}

export function queryCvCharactersByJobId(jobId: string, callback: (success: boolean, resp?: TcCvCharactersResp) => void) {
    const options: RequestInit = {
        method: 'GET',
        headers: {
            'Authorization': getToken()
        },
    };

    fetch(TC_AIUNI_CV_BACKEND_URL + "/queryCvCharactersByJobId?job_id=" + encodeId(jobId), options)
        .then(response => response.json())
        .then(res => {
            try {
                let resp = res as TcCvCharactersResp;
                callback(resp.code === 200, resp);
            } catch (e) {
                callback(false, undefined);
            }
        })
}

export type AddCvCharactersReq = {
    name: string
    rig_model: string
    category: string
}

export type AddCvCharactersResp = {
    error: number
    message: string
    msg: string
    data: {
        job_id: string
    }
}

export function addCvCharacters(req: AddCvCharactersReq, callback: (success: boolean, resp?: AddCvCharactersResp) => void) {
    let innerReq = {
        src_glb: req.rig_model,
        name: req.name,
        category: req.category
    }

    const options: RequestInit = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken()
        },
        body: JSON.stringify(innerReq),
    };

    fetch("https://api.aiuni.ai/openapi/cv/job/character", options)
        .then(response => response.json())
        .then(res => {
            try {
                let resp = res as AddCvCharactersResp;
                callback(resp.error === 0 && resp.msg == "ok", resp);
            } catch (e) {
                callback(false, undefined);
            }
        })
}